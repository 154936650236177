import React from 'react'

class RenderInput extends React.Component {
	render() {
		const { input, label, type, PassReqMobile, isValidatingEmail, meta: { touched, error } } = this.props

		return(
			<div className={`form-group ${touched && error && 'has-error'} ${isValidatingEmail && 'has-warning'}`}>
		    <label>{label}</label>
		    {PassReqMobile}
		    <div>
		      <input
		      	{...input}
		      	placeholder={label}
		      	type={type}
		      	className="form-control"
				autoComplete={type=='password'?'off':''} />
		      {touched && error && <div className="help-block"><div dangerouslySetInnerHTML={{__html: error}}></div></div>}
		      {isValidatingEmail && <div className="help-block">Checking your email...</div>}
		    </div>
		  </div>
		)
	}
}


export default RenderInput
