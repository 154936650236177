
const initializers = {
    version: "2.0.0",
    api_host: process.env.REACT_APP_API_HOST || "https://beta.astrnt.co",
    recruiter_host: process.env.REACT_APP_RECRUITER_HOST || "https://beta.astrnt.co",
    qna_host: process.env.REACT_APP_QNA_HOST || "https://qnb-az.astrnt.co",
    blog_host: process.env.REACT_APP_BLOG_HOST || "https://beta.astrnt.co",
    dev_host: process.env.REACT_APP_DEV_HOST || "http://developer.astrnt.test",
  };
  // commits to trigger master
   
  export default initializers;
