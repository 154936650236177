import { all, takeLatest } from "redux-saga/effects";
import {companiesSwitch, publicCompaniesFetchData} from "./companies"
import {
	usersSaveData,
    usersValidateEmailOnUserRegisterWizardForm,
    usersResendVerificationEmail,
    usersSaveDataGreenHouse,
} from './users'


export default function* root(){
    yield all([
        takeLatest("COMPANIES_SWITCH", companiesSwitch),
        takeLatest("PUBLIC_COMPANIES_FETCH_DATA",publicCompaniesFetchData),
        takeLatest('USERS_SAVE_DATA_REQUEST', usersSaveData),
        takeLatest('USERS_SAVE_DATA_REQUEST_GREENHOUSE',usersSaveDataGreenHouse),
        takeLatest('USERS_VALIDATE_EMAIL_ON_USER_REGISTER_WIZARD_FORM_REQUEST', usersValidateEmailOnUserRegisterWizardForm),
        takeLatest('USERS_RESEND_VERIFICATION_EMAIL_REQUEST', usersResendVerificationEmail),
    ])
}