
import React,{Fragment} from 'react'
import {connect} from 'react-redux'
import PrivacyContent from '../partials/PrivacyContent'
import { Link } from 'react-router-dom'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import $ from 'jquery'

import imgHighTraffic from '../../images/img_hi_traffic_alt1.png'
import { translate } from '../../helpers/global'

class HighTrafficQueue extends React.Component<Props>{
    constructor(props) {
        super(props)

        // bindAll(this, [
        //  'handleCheckInterviewCode'
        // ])
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        $('#public').attr('id','not-public')
    }

  componentWillUnmount(){
    $('#not-public').attr('id','public')
  }
    render(){
        const{globalGetLanguages} = this.props
        const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        const btnBack = (<Link to="/">{translate(globalGetLanguages, '404_BUTTON_BACK')}</Link>)

        return(
            <div id="misc">
            <HeaderNewClear />
            <div className="pad-top desktop-hide" />
            <section>
                <div className="error-404 high-traffic flex-container">
                    <div className="text-404">
                        <label>Our server is currently at full capacity! </label>
                        <div className="desktop-hide">
                            <p>
                                We’re thrilled with the high demand, but we’re on it and will be back up soon.<br/>
                                Thank you for your patience and understanding! <br/>
                                <b>Need help?</b><a href="mailto:help@astrnt.co" className='m-l-8'>Contact Us</a>
                            </p>
                            <p className='m-b-0'>You can wait while the app is trying to reconnect to the server in</p>
                            <h1 className='m-t-0 m-b-0'>30 seconds..</h1>
                        </div>
                    </div>
                    <div><img src={imgHighTraffic} /></div>
                    <div className="mobile-hide">
                        <div className="text-404">
                            <p className='m-b-0 m-t-24'>You can wait while the app is trying to reconnect to the server in</p>
                            <h1 className='m-t-0 m-b-32'>30 seconds..</h1>
                            <p>
                                We’re thrilled with the high demand, but we’re on it and will be back up soon.<br/>
                                Thank you for your patience and understanding! <br/>
                                <b>Need help?</b><a href="mailto:help@astrnt.co" className='m-l-8'>Contact Us</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <FooterNew />
          </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        globalGetLanguages: state.globalGetLanguages,
    }
}


export default connect(mapStateToProps, null)(HighTrafficQueue)

