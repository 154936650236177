//Should consist languages English
export const en = {

    //MOSTLY USED MORE THAN ONCE
    'LOGIN':'Login',
    'LIFE':'Life',
    'PRICING':'Plans',
    'SOLUTIONS': 'Solutions',
    'PRODUCTS': 'Products',
    'COMPANY':'Company',
    'CANDIDATES':'Candidates',
    'ABOUT':'About',
    'PARTNERS':'Partnership',
    'CONTACT':'Contact',
    'HOMEPAGE_BUTTON_START_FREE_TRIAL':'Start your Free Trial',
    'HOMEPAGE_BUTTON_GET_ASTRNT_FOR_FREE':'Get ASTRNT For Free',
    'HOMEPAGE_LABEL_NO_CREDIT_CARD':'No credit card required.',
    'HOMEPAGE_BUTTON_REQUEST_DEMO' : 'Request Demo',
    'ACCELERATE_VIDEO':'Accelerating video interviews & candidate experience for brands around the world',
    'EXPERIENCE_OUR_PLATFORM':'Experience on our platform',
    'PLANS':'Plans',
    'ESSENTIAL':'Essential',
    'ENTERPRISE':'Enterprise',
    'GET_FREE_TRIAL':'Get a free trial',
    'GET_ASTRNT_FOR_FREE':'Get ASTRNT For Free',
    'FEATURES':'Features',
    'ASSESS_TRACK_SELECT':'Assessment, Tracking & Selection',
    'APPLY_NOW':'Apply Now',
    'SEARCH':'Search',
    'REGISTER':'Register',
    'SEARCH_PAGE_NOT_FOUND':'Sorry, we can’t seem to find the page you’re looking for.',
    'SEARCH_PAGE_INCORRECT':'You may have typed the words incorrectly or the page is not available. In the meantime, try again or go back to the homepage.',
    'BACK_TO_HOME_PAGE':'Back to homepage',
    //END

    //start-Header
    


    'HEADER_CANDIDATES_INTERVIEWING': '<b>Candidates</b>: Are you interviewing and need support?',
    'HEADER_GRADUATE_HIRING':'Graduate Hiring',
    'HEADER_GRADUATE_HIRING_TEXT':'Identify future talents early with all-in-one assessment & video tools.',
    'HEADER_UNIVERSITY_TOOLS':'University tools',
    'HEADER_UNIVERSITY_TOOLS_TEXT':'Campus recruiting needs a robust enterprise software solution like ASTRNT’s Tools for Universities & Colleges.',
    'HEADER_PROFESSIONAL':'Professional, all year hiring',
    'HEADER_PROFESSIONAL_TEXT':'Keep your ATS as your central control, and bring consistency and speed to all your roles.',
    'HEADER_GOVERNMENTS':'Governments',
    'HEADER_GOVERNMENTS_TEXT':'Compete with the private sector for the best candidates through transparent processes, video assessment, fit and proper testing.',
    'HEADER_BLUE_COLLAR':'Blue Collar, all year hiring',
    'HEADER_BLUE_COLLAR_TEXT':'Go beyond CVs. Prove identity, assess skills and shortlist fast. Any device, any time, any internet conditions.',
    'HEADER_ASSESMENT_CERT':'Assessment & Certification Centers',
    'HEADER_ASSESMENT_CERT_TEXT':'Stay competitive in an industry digitising fast. Choose a technology partner you can trust.',
    'HEADER_TALENT_PLATFORM':'Talent Platforms Needing Video',
    'HEADER_TALENT_PLATFORM_TEXT':'Bring asynchronous video functionality to your platform, but without the technical complexity.',

    'HEADER_LEARN_MORE':'Learn more',
    
    //end-Header

    //start page FooterNew
    'FOOTER_AUTOMATION':'Automation to help you move fast!',

    'FOOTER_GRADUATE_HIRING':'Graduate Hiring',
    'FOOTER_UNIVERSITY_TOOLS':'University Tools',
    'FOOTER_GOVERNMENTS':'Governments',
    'FOOTER_PROFESSIONAL':'Professional, All Year Hiring',
    'FOOTER_BLUE_COLLAR':'Blue collar, All Year Hiring',
    'FOOTER_ASSESMENT_CERT':'Assessment & Certification Centers',
    'FOOTER_TALENT_PLATFORM':'Talent Platforms Needing Video',
    'FOOTER_FAQ':'How It Works & FAQ',
    'FOOTER_API_DOC':'API Documentation',
    'FOOTER_TOS':'Terms of Service',
    'FOOTER_PRIVACY':'Privacy Policy',
    'FOOTER_LIFE_BLOG':'Life (Our Blog)',
    'FOOTER_CANDIDATE_SUPPORT':'Candidate Support',
    'FOOTER_COPYRIGHT':'Copyrights © 2022 ASTRNT All Rights Reserved.',
    'FOOTER_LANG':'English',

    //end page FooterNew


    //start-Homepagenew 
   'HOMEPAGE_BANNER_TITLE':'Science-Based Holistic Talent Assessment, Applicant Workflow Management ',
   'HOMEPAGE_BANNER_TEXT':'Partnering Employers, Universities & Education Ecosystem',
 
   'HOMEPAGE_CARD_EXPERIENCE_TITLE':'Accelerating video interviews & candidate experience for brands around the world',
   'HOMEPAGE_CARD_EXPLORE_OUR_FEATURES': 'Explore our Features in details',
   'HOMEPAGE_RENDER_BUTTON_FEATURE_1':'Video Interviewing',
   'HOMEPAGE_RENDER_BUTTON_FEATURE_2':'Interview Assessments',
   'HOMEPAGE_RENDER_BUTTON_FEATURE_3':'Structured Interview Builder',
   'HOMEPAGE_RENDER_BUTTON_FEATURE_4':'Complete Assessment Suite',
   'HOMEPAGE_RENDER_TITLE_FEATURE_1':'Understand your candidates',
   'HOMEPAGE_RENDER_TITLE_FEATURE_2':'Set up a fair, efficient screening process',
   'HOMEPAGE_RENDER_TITLE_FEATURE_3':'Maintain a consistent structure',
   'HOMEPAGE_RENDER_TITLE_FEATURE_4':'A Complete assessment tailored to your needs',
   'HOMEPAGE_RENDER_TEXT_FEATURE_1':'Our integrated video system works with any devices with a camera. Asynchronous video helps having simultaneous candidates do assessment interviews at the same time, allows organizations to save time and scale up.',
   'HOMEPAGE_RENDER_TEXT_FEATURE_2':'Understand candidates more and quickly filter candidate that meets the minimum criteria using layered assessments.',
   'HOMEPAGE_RENDER_TEXT_FEATURE_3':'Build customized structured interviews that provide consistency and fairness throughout the interview process with our questions library and templates.',
   'HOMEPAGE_RENDER_TEXT_FEATURE_4':'A complete package of built-in features to enhance the assessment process such as proctoring, candidate sharing, system intelligence (AI), mobile apps, and more.',
   'HOMEPAGE_CARD_CUSTOMERS_SAY':'Customers say',
   'HOMEPAGE_CARD_CUSTOMERS_ACCELERATE':'ASTRNT accelerates the interview process.',
   'HOMEPAGE_CARD_SATISFIED_CANDIDATE':'Our satisfied candidate',
   'HOMEPAGE_ASSESSMENTS':'Assessments',
   'HOMEPAGE_ASSESS_FULLY':'Assessments, fully integrated & priced for volume',
   'HOMEPAGE_WITH_PRICING_PERFECT':'With pricing perfect for high volume, we partner with people analytics experts Talent Data Labs to provide high-quality psychometric assessments, including cognitive and personality. These are fully-integrated and can be combined with on-demand structured interviews.<br/><br/>In addition, our psychometrician team customises so you have the fastest, most accurate shortlisting offering.',
   'HOMEPAGE_FIND_OUT_MORE':'Find out more',
   'HOMEPAGE_INTEGRATIONS':'INTEGRATIONS',
   'HOMEPAGE_WE_LOVE_COLLAB':'We love collaborating with our integration partners',
   'HOMEPAGE_WE_LOVE_PARTNER':'We partner with some of the most renowned names in HR software around the world. This means you can make your next great hire with the tools you need.',
   'HOMEPAGE_CASES_STUDIES':'CASE STUDIES',
   'HOMEPAGE_HIRING_ASSESSMENT':'Hiring & Assessment solutions with your business in mind.',
   'HOMEPAGE_CUSTOMER_SHARE':'Customers share their success stories with our ASTRNT platform and video interviewing.',
   'HOMEPAGE_DOWNLOAD_CASE':'Download case studies',
   'HOMEPAGE_READY_TO_CREATE':'Ready to create a seamless interview process?',
   'HOMEPAGE_WE_HELP':'We help talent ecosystem leaders digitize and automate their processes.',
    'HOME_PAGE_CUSTOMERS_SAY_1':'This is a great app, no sign-in is required for the one who applies for the job. Very simple, just enter the code and take the online interview. The practice session is very great! Thanks!',
    'HOME_PAGE_CUSTOMERS_SAY_2':'What an absolutely amazing experience and I love that one has 3 chances to retake your videos. Initially I was so nervous, however with the practice session it allows one to become more relaxed.',
    'HOME_PAGE_CUSTOMERS_SAY_3':'Woww, I’m impressed by this technology! Using this as a candidate, it enable me to access easily the video interviewing platform. I believe this is the future of a recruitment process that will allow the candidates and recruiters easy to access and also to provide the best talents for the company. Thank you ASTRNT!',
    'HOME_PAGE_CUSTOMERS_SAY_4':'First time using this app, and surprised. This app is very helpful and effective, but  not familiar with this kind of interview. Good job and keep up the good work!',
    'HOME_PAGE_CUSTOMERS_SAY_5':'Very impressive, saves time on answering questions and interviews.',
    'HOME_PAGE_CUSTOMERS_SAY_6':`I'm enjoying this new method and very enthusiastic about the process.`,
    'HOME_PAGE_CUSTOMERS_1':'The ASTRNT platform gave us a holistic view of each candidate to be surgically precise at selecting the very best talents for our NUS cohorts.',
    'HOME_PAGE_CUSTOMERS_2':'I would say this is the smartest decision ever by integrating and collaborating with ASTRNT to embark on a strong digital recruitment strategy.<br/>We consider the recruitment funnel and looked at both candidate experience and process efficiency, to make sure things are scalable in the long run.',
    'HOME_PAGE_CUSTOMERS_3':'I love using the ASTRNT platform.<br/>Technical support is also fantastic and very responsive. Speeds up our fulfillment needs. I am a happy customer!',
    //end-Homepagenew 

    //Start-ArticlesGraduateHiringNew
    'GRADUATE_TAG':'ASTRNT FOR GRADUATE HIRING',
    'GRADUATE_BIG_TEXT':'Virtual campus recruitment solution',
    'GRADUATE_FILL_TEXT':'Scalable graduate recruiting requires a reliable, trusted enterprise software solution.<br/><br/>Candidates appreciate the legibility to take interviews from any device, anytime, from anywhere. And  with an on-demand video interview, assessment, and simulation feature, the recruiter can fill roles rapidly with the best students from any campus without needing an on-site visit.',
    
    'GRADUATE_FIND_POTENTIAL':'Find potential, accurately',
    'GRADUATE_FIND_POTENTIAL_TEXT':'Empower your graduate recruiters to assess candidate aptitude, soft skills, and fit without going to campuses.',
    
    'GRADUATE_ALL_UNIV':'All universities in reach',
    'GRADUATE_ALL_UNIV_TEXT':'See more talent from more universities and achieve true scalability. No time zone barriers or challenges.',
    
    'GRADUATE_BOOST_YOUR_BRAND':'Boost your brand, promote diversity',
    'GRADUATE_BOOST_YOUR_BRAND_TEXT':'With a modern experience, busy students appreciate flexibility and fairness, consistent for all.',
    //end-ArticlesGraduateHiringNew


    //START-ArticlesUniversityNew
    'UNIVERSITY_TAG':'ASTRNT FOR UNIVERSITY TOOLS',
    'UNIVERSITY_BIG_TEXT':'All-in-one platform for interviews, assessments & much more',
    'UNIVERSITY_FILL_TEXT':'Transform your admissions and assessments processes with on-demand, timed video, and timed written assessments. Plus proctoring.',

    'UNIVERSITY_GET_HOLISTIC':'Get a holistic view, make fair decisions',
    'UNIVERSITY_GET_HOLISTIC_TEXT':'Automated assessment built on mobile first in mind, assists in handling mass amounts of test. Its efficient for the admissions team, and preferred by most millennials who prefer to engage life through their mobile devices.',
    
    'UNIVERSITY_SAVE_TIME':'Save time, engage preferred fast',
    'UNIVERSITY_SAVE_TIME_TEXT':'Eliminate time-consuming and repetitive tasks so you can focus on getting to know your applicants in a meaningful way.',
    
    'UNIVERSITY_PROCTORING_SOLUTION':'Proctoring solution',
    'UNIVERSITY_PROCTORING_SOLUTION_TEXT':'Move to online proctored exams, tests, and certifications to reach more test takers conveniently. Simplify and automate your process.',
    
    //end-ArticlesUniversityNew

    //Start-ArticlesGovernments
    'GOVERNMENTS_TAG':'ASTRNT FOR GOVERNMENTS',
    'GOVERNMENTS_BIG_TEXT':'Transparent recruiting to match your governance standards & budget',
    'GOVERNMENTS_FILL_TEXT':'Compete for talent against commercial employers using modern technology and promote equal opportunity.',

    'GOVERNMENTS_DECREASE_TIME':'Good Governance Best Practice',
    'GOVERNMENTS_DECREASE_TIME_TEXT':'Move to online proctored exams, tests, and certifications to ensure you have the right candidates through secure fit-proper tests.',

    'GOVERNMENTS_MEASURE':'Fair & Unbiased For All Candidates',
    'GOVERNMENTS_MEASURE_TEXT':'Empower your selection decisions by quickly assessing all applicants for key traits like leadership, critical thinking, and communication skills. Give every applicant an equal opportunity by building a standardized assessment to reduce bias with automated scoring.',
    
    'GOVERNMENTS_BUILD_TRUST':'Save Cost, Focus on Improvements',
    'GOVERNMENTS_BUILD_TRUST_TEXT':'Easily handle large mass hiring projects, saving cost, and helping monitor performance through annual reviews. Eliminate time-consuming, costly and repetitive tasks so you can focus on getting to know your department employees in a meaningful way.',
    //End-ArticlesGovernments

    //Start-ArticlesProfessionalsNew
    'PROFESSIONAL_TAG':'ASTRNT FOR PROFESSIONAL',
    'PROFESSIONAL_BIG_TEXT':'Make the complete transformation',
    'PROFESSIONAL_FILL_TEXT':'Bring consistency and speed to all your roles, inviting hiring managers to be involved in the process without wasting their time. Engage both passive and active candidates by making it easy for them to participate, and keep your ATS as your central control.',

    'PROFESSIONAL_DECREASE_TIME':'Decrease time to hire',
    'PROFESSIONAL_DECREASE_TIME_TEXT':'Scheduling interviews during a candidate’s work day is not ideal. Empower candidates to interview on their time, on whatever device they prefer.',

    'PROFESSIONAL_MEASURE':'Measure what matters',
    'PROFESSIONAL_MEASURE_TEXT':'University grades and years of experience aren’t strong predictors of job performance. Create a structured interview experience, to reveal the insights you need to make fair, fast and smart decisions.',
    
    'PROFESSIONAL_BUILD_TRUST':'Build trust with hiring stakeholders',
    'PROFESSIONAL_BUILD_TRUST_TEXT':'Create confidence in your processes, and make better decisions, by sharing interviews and results with hiring managers to collect instant feedback.',
    //End-ArticlesProfessionalsNew

    //Start-ArticlesBlueNew
    'BLUE_COLLAR_TAG':'ASTRNT FOR BLUE COLLAR',
    'BLUE_COLLAR_BIG_TEXT':'Holistic screening, accessible for candidates',
    'BLUE_COLLAR_FILL_TEXT':'Offer candidates a much faster and convenient way to participate, on any device, at any time, even in poor internet conditions. Assess for all aspects using realistic job previews, language ability & more.',

    'BLUE_COLLAR_CARD_1':'More productive team managers',
    'BLUE_COLLAR_CARD_TEXT_1':'CV-driven hiring processes add weeks to time-to-hire and reduce productivity of busy managers. Save them from wasting this valuable time.',

    'BLUE_COLLAR_CARD_2':'Skills-based assessment',
    'BLUE_COLLAR_CARD_TEXT_2':'Grades and years of experience aren’t strong predictors of job performance. Test for a range of skills quickly and easily, by creating a structured multi-media interview experience.',
    
    'BLUE_COLLAR_CARD_3':'Trusted, fast and reliable',
    'BLUE_COLLAR_CARD_TEXT_3':'Eliminate the need to coordinate hectic schedules, and maximise completion rates by providing a job-relevant and candidate friendly, interview experience.',
    //End-ArticlesBlueNew

    //Start-ArticlesAsessNew
    'ASSESS_COLLAR_TAG':'ASTRNT FOR ASSESSMENT & CERTIFICATION CENTERS',
    'ASSESS_COLLAR_TAG_BIG_TEXT':'Offline to online with a trusted technology partner',
    'ASSESS_COLLAR_TAG_FILL_TEXT':'Transform your business with a technology partner you can trust. Reliable, flexible, cost-effective and committed to the future of the assessment industry. From online to online to automation and algorithms, we are with you.',

    'ASSESS_COLLAR_CARD_1':'Reliable, flexible, cost-effective',
    'ASSESS_COLLAR_CARD_TEXT_1':'Choose us as your technology platform partner for your business. We grow with you, with different partnership models to keep your costs low and your reliability high.',

    'ASSESS_COLLAR_CARD_2':'Scale your impact with digitisation',
    'ASSESS_COLLAR_CARD_TEXT_2':'By offering on-demand online services you can reach customers anywhere, anytime. You don’t have geographic constraints anymore.',
    
    'ASSESS_COLLAR_CARD_3':'Assessments, fully integrated & priced for volume',
    'ASSESS_COLLAR_CARD_TEXT_3':'We partner with people analytics experts Talent Data Labs to provide high-quality psychometric assessments, including cognitive and personality. In addition, our psychometrician team customises so you have the fastest, most accurate shortlisting offering.',
    //End-ArticlesAsessNew

    //Start-ArticlesTalentNew
    'TALENT_COLLAR_TAG':'ASTRNT FOR TALENT PLATFORMS NEEDING VIDEO',
    'TALENT_COLLAR_TAG_BIG_TEXT':'Reliable asynchronous video, ready for fast integration',
    'TALENT_COLLAR_TAG_FILL_TEXT':'We empower jobs boards, ATS, HRIS and assessment platforms that want to offer asynchronous video functionality to users, but don’t want the hassle of building and maintaining it themselves. We can be your trusted technology partner.',

    'TALENT_COLLAR_CARD_1':'Make your talent platform stand out',
    'TALENT_COLLAR_CARD_TEXT_1':'By building on top of our platform, you can create bespoke asynchronous video experiences. Our APIs and SDKs allow you to serve all major browsers and devices with video.',
    'TALENT_EXPLORE_OUR_API':'Explore our API & SDK',

    'TALENT_COLLAR_CARD_2':'Build video features that scale',
    'TALENT_COLLAR_CARD_TEXT_2':'Develop, launch, and run your application on top of our cloud-based, enterprise-grade infrastructure. Our documentation and support provide all you need to build customized video applications at scale.',
    
    'TALENT_COLLAR_CARD_3':'We make it easy for you',
    'TALENT_COLLAR_CARD_TEXT_3':'We are the easiest way to add asynchronous video to your talent platform. Get up and running in hours, not months, freeing up your team to focus on your business.',
    'TALENT_TEST_OUR_VIDEO_API':'Test Our Video API',
    
    //End-ArticlesTalentNew


    //Start-HowItWorkNew
    'PRICING_TITLE': 'Flexible pricing that <strong>fits your team size.</strong>',
    'PRICING_DESCRIPTION': 'No credit card required in your first 14 days.',
    'PRICING_LABEL_PRICE_IN_USD': '*Price in USD',
    'PRICING_ESSENTIAL_DESC': '<p><strong>Plan designed for</strong> Startups, Small and Medium<br/>Enterprises</p>',
    'PRICING_ESSENTIAL_PRICE_MONTHLY' : '$<strong>88</strong> <span>/month</span',
    'PRICING_ESSENTIAL_PRICE_YEARLY' : '$<strong>900</strong> <span>/year</span>',
    'PRICING_SAVE_COMPARED_MONTHLY' : 'Save 15% compared to monthly',
    'PRICING_PLAN_FOR_LARGE_VOLUMES': '<p><strong>Plan designed for</strong> Large Volumes</p>',
    'PRICING_PLAN_CUSTOM_PRICING':'Custom Pricing',
    'PRICING_OFFER_ENTERPRISE':'We offer a very competitive pricing to match your business needs. Get in touch and we will reach back to create your custom pricing plan.',
    'PRICING_SWITCH_PLAN':'Switch Plan',
    'PRICING_ESSENTIAL_MONTLY':'Essential Monthly',
    'PRICING_ESSENTIAL_YEARLY':'Essential Yearly',
    'PRICING_PLAN_DESIGNED_MOBILE':'<strong>Plan designed for</strong> Startups, Small and Medium Enterprises',
    'PRICING_PLAN_DESIGNED_MOBILE_LARGE':'<strong>Plan designed for</strong> Large Volumes',
    
    //Features pricing
    'UNLIMITED':'Unlimited',
    'PRICING_#_CANDIDATE_RESPONSE_1':'# of candidate responses',
    'PRICING_#_CANDIDATE_RESPONSE_2':'30/month',
    'PRICING_#_CANDIDATE_RESPONSE_3':'360/year',
    'PRICING_#_USER_TEAM_MEMBER_RESPONSE':'# of users or team member',
    'PRICING_#_OF_JOBS':'# of jobs',
    
    'PRICING_VIDEO_INTERVIEW':'Video Interview',
    'PRICING_MCQ':'Multiple Choice Questions',
    'PRICING_FTQ':'Free Text Questions',
    'PRICING_RANDOM_QUESTION':'Randomization Question',
    'PRICING_VOICE_TO_TEXT':'Voice to Text Transcription',
    'PRICING_PROCTORING':'Proctoring',
    'PRICING_CO_BRAND':'Co-brand invitation',
    'PRICING_CANDIDATE_REVIEWER':'Candidate and Reviewer Analytics',
    'PRICING_QUESTIONS_BANK':'Question Banks',
    'PRICING_CANDIDATE_DOWNLOAD':'Candidate Download and Upload Documents',
    'PRICING_INCLUDED_WHEN_AVAILABLE':'Included when available, coming soon.',
    'PRICING_AVAILABLE_UPON_REQUEST':'Available, upon request.',

    'PRICING_CANDIDATE_EXPERIENCE':'Candidate Experience',
    'PRICING_CANDIDATE_EXPERIENCE_TEXT_1':'White label candidate experience',
    'PRICING_CANDIDATE_EXPERIENCE_TEXT_2':'Welcome Video',
    'PRICING_CANDIDATE_EXPERIENCE_TEXT_3':'WhatsApp Integration',

    'PRICING_DATA_SECURITY':'Data Security',
    'PRICING_DATA_SECURITY_TEXT_1':'ISO 27001 Compliant',

    'PRICING_CANDIDATE_MANAGEMENT':'Candidate Management',
    'PRICING_CANDIDATE_MANAGEMENT_TEXT_1':'Smart Filtering',
    'PRICING_CANDIDATE_MANAGEMENT_TEXT_2':'Candidate Scoring Report',
    'PRICING_CANDIDATE_MANAGEMENT_TEXT_3':'Automatic Calculation Scoring on MCQ',
    'PRICING_CANDIDATE_MANAGEMENT_TEXT_4':'Share Candidate',
    'PRICING_CANDIDATE_MANAGEMENT_TEXT_5':'Think Time',

    'PRICING_INTEGRATION':'Integration',
    'PRICING_INTEGRATION_TEXT_1':'ATS Integration',

    'PRICING_SUPPORT':'Support',
    'PRICING_SUPPORT_TEXT_1':'Dedicated Onboarding',
    'PRICING_SUPPORT_TEXT_2':'Dedicated Customer Success Manager',
    'PRICING_SUPPORT_TEXT_3':'Live Chat',
    'PRICING_SUPPORT_TEXT_4':'Question Set-Up',
    'PRICING_SUPPORT_TEXT_5':'Features request and additional tech support',

    'PRICING_ARE_YOU_NGO':'Are you NGO or a start up company?',
    'PRICING_ARE_YOU_NGO_TEXT_1':'Find your eligiblity to get 1st year discount by filling in your details and create a free trial for 14 days',


    'PRICING_FAQ':'Pricing Frequently Asked Questions',
    
    'PRICING_FAQ_QUESTION_1':'What is a candidate credit?',
    'PRICING_FAQ_ANSWER_1':' A candidate credit is defined as the ability for a candidate to complete a session in ASTRNT, i.e. if the candidate does not complete the questions your account will not be charged. It is 1 credit for 1 completed candidate.',

    'PRICING_FAQ_QUESTION_2':'What does the 12 months validity mean?',
    'PRICING_FAQ_ANSWER_2':'All of your candidate credits hold a 12 months validity based on your subscription plan and you are encouraged to use them before they expire. If you require flexibility for the period of time, please contact us at <a target="_blank" rel="noopener noreferrer" href="mailto:help@astrnt.co">help@astrnt.co</a>, we are here to help always. Also, see below regarding how to extend the validity of existing credits by purchasing new credits.',

    'PRICING_FAQ_QUESTION_3':'What happens to my existing credits?',
    'PRICING_FAQ_ANSWER_3':'When you top-up your credits, a new 12 month validity will be applied to all your existing credits.',
    
    'PRICING_FAQ_QUESTION_4':'When can I use my newly bought credits?',
    'PRICING_FAQ_ANSWER_4':'We operate on a trust basis and allow you to access your new credits right away. All we ask in return is a speedy payment in response to the invoice and tax documents. We may need to suspend your account if we don’t receive payment within 30 days of sending the invoice.',

    'PRICING_FAQ_QUESTION_5':'My credits are currently in negative. Will I be billed twice?',
    'PRICING_FAQ_ANSWER_5':'No, after you purchase new credits they will be applied to offset the deficit.',

    'PRICING_FAQ_QUESTION_6':'What payment methods are accepted?',
    'PRICING_FAQ_ANSWER_6':'We accept Credit Cards (American Express, China Union Pay, Discover & Diners, Japan Credit Bureau, Master Card, Visa), ATM/Bank Transfer, KlikBCA, BCA KlikPay, Octo Clicks, and e-Pay BRI. For Enterprise subscribers we also accept direct bank transfer. ',
    
    'PRICING_STILL_HAVE_QUESTION':'Still have questions?',
    'PRICING_LETS_CHAT_WITH_US':`Let's <a target="_blank" rel="noopener noreferrer" href="mailto:help@astrnt.co">chat with us</a>`,
    //End-pricing

    //Start-HowItWorksNew
    'HOW_IT_WORKS_TITLE':'Get to know how to simplify & accelerate your hiring process',
    'HOW_IT_WORKS_CARD_TITLE_1':'Asynchronous Video Interviews',
    'HOW_IT_WORKS_CARD_TEXT_1':'Make interviews fast with asynchronous video and get faster responses from candidates faster.',

    'HOW_IT_WORKS_CARD_TITLE_2':'Full Suite Assessments Tools',
    'HOW_IT_WORKS_CARD_TEXT_2':'Thoroughly measure your candidates with multiple choice questions, free-text, grouped questions, and questions database.',

    'HOW_IT_WORKS_CARD_TITLE_3':'Assessment Partners',
    'HOW_IT_WORKS_CARD_TEXT_3':'Don’t worry if you need help asking the right questions. Work together with our assessment partners or your desired assessment partners to create a fully customized questions library.',

    'HOW_IT_WORKS_CARD_TITLE_4':'Share',
    'HOW_IT_WORKS_CARD_TEXT_4':'Get notes and opinions from your peers and managers by sharing candidates to get results fast.',

    'HOW_IT_WORKS_CARD_TITLE_5':'Dedicated Questions Library',
    'HOW_IT_WORKS_CARD_TEXT_5':'Plan and be prepared. Populate your dedicated questions library with input from your managers and stakeholders, and create interview templates for future use.',

    'HOW_IT_WORKS_CAN_WE_HELP':'How can we help?',
    '':'',

    //End-HowItWorksNew

    //Start-PartnersPageNew
    'PARTNERS_PAGE_CARD_1_TITLE':'Together we can do so much',
    'PARTNERS_PAGE_CARD_1_TEXT':'The global digital talent ecosystem is growing fast. What role are you playing? We partner with agencies, consultants, assessors, entrepreneurs and other talent platforms all over the the world.',

    'PARTNERS_PAGE_CARD_2_TITLE':'Our Partnership Type',

    'PARTNERS_PAGE_CARD_2_CONTENT_TITLE_1':'Regional Partners',
    'PARTNERS_PAGE_CARD_2_CONTENT_TEXT_1':'Nothing beats local knowledge and a great reputation. Help us build our global footprint in you part of the world. We’re flexible to your needs and we look forward to learning about you.',

    'PARTNERS_PAGE_CARD_2_CONTENT_TITLE_2':'Talent Technology Team-Ups',
    'PARTNERS_PAGE_CARD_2_CONTENT_TEXT_2':'The digital talent ecosystem is becoming increasingly connected to be able to meet the needs of the market. Move fast and innovate with us to offer a complete solution for your industry.',

    
    'PARTNERS_PAGE_CARD_2_CONTENT_TITLE_3':'Consultants & Content Owners',
    'PARTNERS_PAGE_CARD_2_CONTENT_TEXT_3':'Do you have the expertise and content, but need a trusted technology partner to pursue your ambitions? We can help.',

    'PARTNERS_PAGE_CARD_3_TITLE':'Partner with ASTRNT',
    'PARTNERS_PAGE_CARD_3_TEXT':'We welcome partnerships.<br/>Fill out the form below, and we will contact you with priority.',

    //Form Partnership
    'PARTNERS_FORM_LABEL_1':'Company/Organisation *',
    'PARTNERS_FORM_LABEL_2':'Email Address *',
    'PARTNERS_FORM_LABEL_3':'Name *',
    'PARTNERS_FORM_LABEL_4':'Mobile Phone *',
    'PARTNERS_FORM_LABEL_5':'Tell us why and how your company would partner with us *',

    'PARTNERS_FORM_PLACEHOLDER_1':'e.g: Talent Data Labs',
    'PARTNERS_FORM_PLACEHOLDER_2':'e.g: johndean@mail.com',
    'PARTNERS_FORM_PLACEHOLDER_3':'e.g: John Dean',
    'PARTNERS_FORM_PLACEHOLDER_4':'e.g: +61 8888 4466',

    'PARTNERS_FORM_SUBMIT_BUTTON':'Send Message',
    //End Form

    //End-PartnersPageNew

    //Start-UserRegisterWizardFormContainerNew
    'REGISTER_PAGE_A_FASTER':'A faster, smarter way that candidates love.',
    'REGISTER_PAGE_JOIN_HUNDREDS':'Join hundreds of talent ecosystem leaders digitizing and automating their selection processes and keeping their organisations safe & agile.',

    'REGISTER_PAGE_NO_CREDIT_CARD':'No credit card required.',
    'REGISTER_PAGE_14_DAYS':'14 days free trial',
    'REGISTER_PAGE_UNLIMITED_JOBS':'Unlimited jobs',
    'REGISTER_PAGE_UNLIMITED_USERS':'Unlimited users or team members',
    'REGISTER_PAGE_LIVE_CHAT':'Live chat support',

    //REGISTER POPUP
    'REGISTER_POP_WHAT_BEST':'What best describes your focus?',
    'REGISTER_POP_UNIV_ADMISSION':'University Admissions',
    'REGISTER_POP_RECRUIT':'Recruitment & Assessment',
    'REGISTER_POP_APPLICANT':'I’m An Applicant',

    'REGISTER_POP_IF_YOU_APPLICANT':"If You're An Applicant",

    'REGISTER_POP_OK_SUPER':'Ok, super. That’s great to know.',
    'REGISTER_POP_APPLICANTS_SHOULD':'Applicants should proceed as follows:',
    'REGISTER_POP_CHECK_EMAIL':'Check the email sent to you by the organization you’re interviewing with.',
    'REGISTER_POP_INSERT_CODE':'Insert the provided code to ',
    'REGISTER_POP_START_INTERVIEW':'Start the interview.',
    'REGISTER_POP_NEED_ASSISTANCE':'If you need assistance with your interview, please contact our ',
    'REGISTER_POP_SUPPORT_TEAM':'support team.',

    //Form Register
    'REGISTER_FORM_GET_TO_KNOW':'Get Ready, Set-Up is Easy',
    'REGISTER_FORM_WHAT_BEST':'What best describes your focus?',

    'REGISTER_FORM_LABEL_NAME_COMPANY':'Name of Company/Organization *',
    'REGISTER_FORM_LABEL_WORK_EMAIL':'Work Email *',
    'REGISTER_FORM_LABEL_WORK_PHONE':'Work Phone *',
    'REGISTER_FORM_LABEL_FULLNAME':'Full Name *',
    'REGISTER_FORM_LABEL_PREFERRED_NAME':'Preferred Name *',
    'REGISTER_FORM_LABEL_PASSWORD':'Password *',

    'REGISTER_FORM_LABEL_HOW_DO_YOU_HOPE':'How do you hope ASTRNT can help achieve your goals? *',
    'REGISTER_FORM_PLACEHOLDER_I_HOPE':'I hope ASTRNT can help us...',

    'REGISTER_FORM_BY_CLICK':`By clicking "Start Free Trial", you agree to ASTRNT's `,
    'REGISTER_FORM_AND':" and ",
    'REGISTER_FORM_ALREADY_HAVE':"Already have an account? ",
    'REGISTER_FORM_LOGIN':"Log in",

    'REGISTER_FORM_SUBMIT_BUTTON':'Create Account',
    //End Form

    //End-UserRegisterWizardFormContainerNew

    ///Start-CandidateSupport
    'CANDIDATE_SUPPORT_CARD_1_TITLE':'Interview & Assessments Has Never Been Simpler',
    'CANDIDATE_SUPPORT_CARD_1_TEXT':"ASTRNT gives you the stage and the chance to shine beyond your CV. Asynchronous video lets you record your interview answers from anywhere, anytime, on your laptop or mobile phone.",

    'CANDIDATE_SUPPORT_CARD_2_TITLE':'Better experience',
    'CANDIDATE_SUPPORT_CARD_2_TEXT':'People love our interview experience',
    'CANDIDATE_SUPPORT_CARD_REVIEW_APPLE':'Read review on Apple Store',
    'CANDIDATE_SUPPORT_CARD_REVIEW_GOOGLE':'Read review on Google Play Store',

    'CANDIDATE_SUPPORT_CARD_3_TITLE':'We got your back',
    'CANDIDATE_SUPPORT_CARD_3_TEXT':'Don’t be nervous about your interviews, ASTRNT is here to support you for your next big win.',
    
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TITLE':'Prepare for your interview',

    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_1':'5 tips to prepare for your interview',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_2':'#1 Do your homework',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_3':'#2 Non-Verbal Communiation',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_4':'#3 Manners',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_5':'#4 Sell Yourself',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_6':'#5 Be Prepared',

    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_1':`ASTRNT's assessments and interviews are quick and easy to use, 
    but sometimes people get nervous before an interview, so here are 
    our Top 5 Tips to prepare you for an interview.`,
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_2':'Do a small research on the company you are applying to. Understanding what they do and about their products will give you confidence and be relatable when answering questions.',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_3':'When taking online interviews, start by making eye contact with the camera. Find a comfortable sitting posture, smile, and avoid looking at your phone or other distraction.',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_4':'Be mindful of your language, be friendly but not too friendly, and always be polite.',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_5':'Don’t be afraid to tell people about what you can do, your expertise, and your past success.',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_6':'Have your CV, photo, diplomas, etc, prepared in digital files just in case they needed to be uploaded. Dress well and be confident.',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_FIND_MORE':'Find more articles to help you prepare for interviews at',

    'CANDIDATE_SUPPORT_CARD_3_CONTENT_2_TITLE':'Do a test interview',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_2_TEXT':`Experience first hand what ASTRNT's interview process feels like, it's a snap!`,
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_2_TEST_QNA':`Test Q&A ASTRNT`,

    'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_TITLE':'Express Yourself',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_INTRO_TEXT':`Don't be afraid to let them know more about you. Here are a few tips for great video interviews:`,

    'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_LABEL_1':'#Wear Outfits That Represents You',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_LABEL_2':'#Choose Your Scenes',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_LABEL_3':'#Speak Clearly',

    'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_TEXT_1':'Being tidy and formal is always the best choice for an Interview, but add your personality to make yourself stand out and memorable. From your favorite color to accessories, but don’t be too distracting.',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_TEXT_2':'The great thing about online interviews is that you can do it anywhere. At a café, a park, your living room, or anywhere that represents who you are. Make sure its bright so that they can see your face, and make sure its not noisy so you can be heard.',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_TEXT_3':'Don’t be afraid to share your opinions, but make sure you do it in a clear voice. Practice your vocals by talking some sentences while biting a pencil, or articulating your jaw',



    'CANDIDATE_SUPPORT_CARD_4_TITLE':'Frequently Asked Questions',

    'CANDIDATE_SUPPORT_FAQ_1':'What is a code? Why do I need one?',
    'CANDIDATE_SUPPORT_FAQ_2':'Where do I find my code?',
    'CANDIDATE_SUPPORT_FAQ_3':'I have a code for an ASTRNT Q&A session, what should I do with it?',
    'CANDIDATE_SUPPORT_FAQ_4':'I have received an invitation email, what do I do next?',
    'CANDIDATE_SUPPORT_FAQ_5':`I can't see my face on the screen, what should I do?`,
    'CANDIDATE_SUPPORT_FAQ_6':`The application isn't recording my voice, what should I do?`,
    'CANDIDATE_SUPPORT_FAQ_7':`I can't install the ASTRNT Q&A mobile app. Why?`,
    'CANDIDATE_SUPPORT_FAQ_8':`The Android app keeps crashing on my device. What should I do?`,
    'CANDIDATE_SUPPORT_FAQ_9':`In which web browsers can I do my Q&A session?`,
    'CANDIDATE_SUPPORT_FAQ_10':`I ran out of time but I haven't completed all the questions. What should I do?`,
    'CANDIDATE_SUPPORT_FAQ_11':`I got disconnected from the internet during my Q&A session. What should I do?`,
    'CANDIDATE_SUPPORT_FAQ_12':`I experienced some technical issues during my Q&A session and I want to get a second chance. What should I do?`,
    'CANDIDATE_SUPPORT_FAQ_13':'Have you received my answers?',
    'CANDIDATE_SUPPORT_FAQ_14':`I've finished my Q&A session. How do I get my results? What should I do next ?`,
    'CANDIDATE_SUPPORT_FAQ_15':'I received an email asking for my document. What should I do?',
    'CANDIDATE_SUPPORT_FAQ_16':'ASTRNT sounds awesome. How do I join the team?',


    'CANDIDATE_SUPPORT_FAQ_1_TEXT':`The ASTRNT Q&A code is what ASTRNT uses to make sure you get the right questions. For every code, 
    there is a specific set of questions and scenarios, depending on what your recruiter wants to know about you.`,
    
    
    'CANDIDATE_SUPPORT_FAQ_2_TEXT':`<li>
      If:
      <ul>
        <li>Your recruiter invites you to do a Q&A on ASTRNT, please check your email to find your code.</li>
        <li>You download the app because of an advertisement, please check the advertisement to find an open code.</li>
        <li>You’d like to apply to ASTRNT, please see the last FAQ for our code.</li>
      </ul>
    </li>`,

    'CANDIDATE_SUPPORT_FAQ_3_TEXT':`<li>
                    You’re all set for your Q&A session! There are two ways you can do it:
                    <ul>
                      <li>
                        <b>Doing the Q&A session on ASTRNT mobile app</b>
                        Install the ASTRNT Q&A mobile app (available in both <a href="https://apps.apple.com/id/app/astronaut-q-a/id1098124247" target="_blank" rel="noopener noreferrer">iOS</a> and <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer">Android</a>), input your code, and 
                        follow the instructions in the mobile app. 
                      </li>
                      <li>
                        <b>Doing the Q&A session on desktop browser</b>
                        Go to <a href="http://astrnt.co" target="_blank" rel="noopener noreferrer">http://astrnt.co</a>, click on "I have a code", and input your code. Then follow the instructions to complete your Q&A session.
                      </li>
                    </ul>
                    We recommend you find a quiet place and enough time to do the test without distractions. Make sure you have a stable internet connection as well. Good luck!
                  </li>`,

    'CANDIDATE_SUPPORT_FAQ_4_TEXT':`<li>
    You’re all set for your Q&A session! There are two ways you can do it:
    <ul>
      <li>
        <b>Doing the Q&A session on ASTRNT mobile app</b>
        Install the ASTRNT Q&A mobile app (available in both <a href="https://apps.apple.com/id/app/astronaut-q-a/id1098124247" target="_blank" rel="noopener noreferrer">iOS</a> and <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer">Android</a>), input your code, and 
        follow the instructions in the mobile app. 
      </li>
      <li>
        <b>Doing the Q&A session on desktop browser</b>
        Go to <a href="http://astrnt.co" target="_blank" rel="noopener noreferrer">http://astrnt.co</a>, click on "I have a code", and input your code. Then follow the instructions to complete your Q&A session.
      </li>
    </ul>
    We recommend you find a quiet place and enough time to do the test without distractions. Make sure you have a stable internet connection as well. Good luck!
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_5_TEXT':`<li>
                    Please check your camera permission. Here’s how:
                    <ul>
                      <li>
                        <b>Using Chrome</b>
                        Follow the instructions in <a href="https://support.google.com/chrome/answer/2693767?hl=en" target="_blank">this link</a> to change camera access permission.
                      </li>
                      <li>
                        <b>Using Mozilla Firefox</b>
                        On the menu bar, go to Tools > Page Info > Permissions, then scroll down to "Use the Camera". Make sure the website is allowed to use the camera.
                      </li>
                      <li>
                        <b>Using Opera</b>
                        By default, Opera will always ask you to allow a site to access your camera. If this isn’t the case, go to Settings > Websites, under Camera, and click Manage Exceptions button. For more information, <a href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#camera" target="_blank">click here</a>.
                      </li>
                      <li>
                        <b>Using ASTRNT Q&A app on iOS</b>
                        Go to Settings > Privacy > Camera, and make sure ASTRNT has access to your camera.
                      </li>
                      <li>
                        <b>Using ASTRNT Q&A app on Android</b>
                        Go to Settings > Security & Privacy > Application Permissions and make sure ASTRNT has access to your camera.
                      </li>
                    </ul>
                  </li>`,

    'CANDIDATE_SUPPORT_FAQ_6_TEXT':`<li>
                    Please check your microphone permission. Here’s how:
                    <ul>
                      <li>
                        <b>Using Chrome</b>
                        Follow the instructions in <a href="https://support.google.com/chrome/answer/2693767?hl=en" target="_blank">this link</a> to change camera access permission.
                      </li>
                      <li>
                        <b>Using Mozilla Firefox</b>
                        On the menu bar, go to Tools > Page Info > Permissions, then scroll down to "Use the Microphone". Make sure the website is allowed to use the microphone.
                      </li>
                      <li>
                        <b>Using Opera</b>
                        By default, Opera will always ask you to allow a site to access your microphone. If this isn’t the case, go to Settings > Websites, under Microphone, and click Manage Exceptions button. For more information, <a href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#microphone" target="_blank">click here</a>.
                      </li>
                      <li>
                        <b>Using ASTRNT Q&A app on iOS</b>
                        Go to Settings > Privacy > Microphone, and make sure ASTRNT has access to your microphone.
                      </li>
                      <li>
                        <b>Using ASTRNT Q&A app on Android</b>
                        Go to Settings > Security & Privacy > Application Permissions and make sure ASTRNT has access to your microphone.
                      </li>
                    </ul>
                  </li>`,

    'CANDIDATE_SUPPORT_FAQ_7_TEXT':`<li>
    The ASTRNT Q&A app is available in both <a href="https://apps.apple.com/id/app/astronaut-q-a/id1098124247" target="_blank" rel="noopener noreferrer">iOS</a> (iOS 12 & above) and <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer">Android</a> (6.0 & above, with minimum 2GB RAM). 
    If your phone doesn’t meet this requirement, we recommend doing the Q&A session on your desktop browser.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_8_TEXT':`<li>
    Sorry about that! On a very rare occasion we do encounter a problem in Android, particularly Nexus devices. 
    If you experience this with your device, we encourage you to report it to us. To complete your Q&A session, we recommend using our <a href="http://astrnt.co" target="_blank">website</a> on your desktop.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_9_TEXT':`<li>
    We support <a href="https://www.google.com/intl/en/chrome/browser/" target="_blank">Google Chrome</a> (recommended), <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a>, and <a href="http://www.opera.com/" target="_blank">Opera</a>. Unfortunately, we do not currently support Safari, 
    Microsoft Edge, and Internet Explorer. These browsers are still in the experimental stage since WebRTC support 
    was only recently released in these systems. Our team is working hard to work out the bugs before we roll out support for these browsers.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_10_TEXT':`<li>
    Don’t worry, all the answers you sent before the time runs out are uploaded to our database. However, Team ASTRNT 
    doesn't have any rights to give you a second chance. If you wish, you can contact your recruiter directly to request 
    a second chance. If you think the timer ran out by mistake, please contact us at <a href="mailto:help@astrnt.co" target="_blank">help@astrnt.co</a>.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_11_TEXT':`<li>
    Unfortunately, if you are in the middle of your Q&A session, the timer will keep running even 
    though you get disconnected. Please try to reconnect immediately.
    If you lose connection after you have completed all the questions, no need to worry because your 
    answers are still there—you just need to reconnect to the internet and the upload process will 
    continue. Our system does spot checks of your speed to avoid this problem.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_12_TEXT':`<li>
    If you experienced any technical issues during your Q&A session, please contact <a href="mailto:help@astrnt.co" target="_blank">help@astrnt.co</a>. 
    However, Team ASTRNT doesn't have any rights to give you a second chance. Please contact your 
    recruiter directly to request a second chance.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_13_TEXT':`<li>
    At the end of your Q&A session, you should see an upload page that shows the status of your upload. 
    Once all answers are uploaded, our system will send you a confirmation email. This means that your 
    answers are safe and sound on our servers.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_14_TEXT':`  <li>
    Great job! For further information about your results and recruitment process, please contact your recruiter directly.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_15_TEXT':`<li>
    You received this email because the recruiter would like to have your document. Please read 
    the email carefully to know what document is requested, and reply to the email with your 
    document attached. The document must be in PDF format.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_16_TEXT':`<li>
    ASTRNT team is always interested in receiving and watching video applications. 
    Use the code "talentpool" on ASTRNT to apply to work with us, or <a href="https://bit.ly/astrnt_talentpool" target="_blank">click here</a>.
  </li>`,

    //End-CandidateSupport


    //start-PageNotFound
    '404_OOPS': 'Oops!',
    '404_BUTTON_BACK': 'Back to homepage',
    '404_DESKTOP_HIDE_TEXT': '<span>Sorry, the page you’re looking for does not exist.</span><p>The link you clicked maybe broken or the page may have been removed.<br/>Please go back to the homepage.</p>',
    '404_MOBILE_HIDE_TEXT': '<span>Sorry, the page you’re looking for does not exist.</span><p>The link you clicked maybe broken or the page may have been removed. Please go back to the homepage.</p>',
    //end-PageNotFound

    // Start-About
    'ABOUT_CARD_1_TITLE': 'If You Want To Go Far, Go Together',
    'ABOUT_CARD_1_TEXT': `Our mission at ASTRNT is to make recruitment fast, fun & engaging for everyone, by transforming the way companies get to know 
        candidates & the way candidates get to know about the places at which they hope to work. And we put video-selfies at the core of 
        the experience. Why video? It’s for humans. Plus they’re rich in data.`,
    'ABOUT_CARD_2_TITLE': 'Our history',
    'ABOUT_CARD_2_TEXT': `After years of team building and agency recruiting we knew we could make a meaningful contribution to the talent ecosystem. 
        And so, ASTRNT was born. Our original mission was to make recruitment fast, fun & engaging for everyone by putting video 
        selfies at the core of the experience. That’s still our core but now we do much more. We are on a mission to help employers, 
        universities, and the entire talent ecosystem deliver more fair, faster, and greater access to opportunity and personal growth 
        to a stronger community.`,
    'ABOUT_CARD_3_TITLE': 'Meet our people',
    'ABOUT_CARD_3_TEXT': 'We value our people at ASTRNT.<br/>This is our team, a lot of smiling happy people that work hard to empower your teams.',
    'ABOUT_CARD_4_TITLE': 'ASTRNT Leadership',
    'ABOUT_CARD_4_CEO_DESC': `Civil Engineer with experience in Asian startups and investing in India, Shanghai and Indonesia, in the fields of human resources and growth.`,
    'ABOUT_CARD_4_CTO_DESC': `This Lund university graduate is an experienced startup CTO in the finance technologies and human resources industry.`,
    'ABOUT_CARD_4_MARKETING_DESC': `20+ years in digital advertising & marketing focused in strategy and production for international brands. Strong education background & founder of POPSkul.id`,
    'ABOUT_CARD_4_PE_QALEAD_DESC': `Former 123RF and OVO lead, Galih is leads the development team in Bandung and supports the CTO’s role in team management and production.`,
    'ABOUT_CARD_4_TALENT_DESC': `Assessment specialist with 10+ years experience in psychometrics, assessment center, competency development, and assessment/recruitment technology.`,
    'ABOUT_CARD_4_PM_DESC': `A full stack scrum master with over 10 years of unique experience in software development and business development.`,
    'ABOUT_CARD_4_PIL_DESC': `Vinsen has lead many of ASTRNT’s integration, from ATS, job portals, to other SaaS HR and internal enterprise HR platforms. He’s the guy who will go with your flow.`,
    'ABOUT_CARD_5_TITLE': `It's all about the people`,
    'ABOUT_CARD_5_SUBTITLE': 'Want to join us?',
    'ABOUT_CARD_5_TEXT': 'We are always open to considering new hires in all roles. Should you find ASTRNT interesting, <br/>please consider taking a short interview to be in our talent pool.',
    'ABOUT_CARD_5_TEXT2': 'It only takes 5 minutes and you get to use our platform!  We’re excited to get to know you.',
    'ABOUT_CARD_5_CHILD_TITLE': 'Join the ASTRNT Talent Pool',
    'ABOUT_CARD_5_CHILD_TEXT': `Join our Talent Community and stay up-to-date on opportunities to join our team, new about <br/> the company and career updates relevant to you.`,
    'ABOUT_CARD_5_CHILD_BUTTON': 'Take a short interview',
    // End-About

    // Start-feature
    'FEATURE_TITLE': 'Features made for your success',
    'FEATURE_1_TITLE': 'Video Interviewing',
    'FEATURE_1_TEXT': `Our integrated video system works on any device with a camera. Asynchronous video helps to have simultaneous 
        candidates do assessment interviews at the same time, allowing organizations to save time and scale-up.`,
    'FEATURE_2_TITLE': 'Structured Interview Builder',
    'FEATURE_2_TEXT': 'Build customized structured interviews that provide consistency and fairness throughout the interview process with our questions library and templates.',
    'FEATURE_3_TITLE': 'Interview Assessments',
    'FEATURE_3_TEXT': 'Understand candidates more and quickly filter candidate that meets the minimum criteria using layered assessments.',
    'FEATURE_4_TITLE': 'Showcase & Welcome Videos',
    'FEATURE_4_TEXT': `Create a better candidate experience through welcome videos. Give brief introductions on the company, about the job, 
        directions on how to take the assessments and interview to help them be more relaxed and confident.`,
    'FEATURE_5_TITLE': 'Interview Evaluation',
    'FEATURE_5_TEXT': 'Automated interview evaluation to quickly create a shortlist through filtering desired outcomes and parameters.',
    'FEATURE_6_TITLE': 'Screening Decisions Collaboration',
    'FEATURE_6_TEXT': 'Work together by sharing candidate profiles and results with teams, stakeholders, or other colleagues involved.',
    'FEATURE_7_TITLE': 'Analytics & Reporting',
    'FEATURE_7_TEXT': 'Report on your hiring process from number of interview invites sent, candidate response time, time to completion, feedback, and more.',
    'FEATURE_8_TITLE': 'Permissions',
    'FEATURE_8_TEXT': `Involve all stakeholders in the right way. Give controlled access to hiring managers, clients and assessors for rating, 
        reviewing and reporting. Also give candidates the right to remove their data so you comply with data privacy and control obligations.`,
    'FEATURE_9_TITLE': 'Proctoring',
    'FEATURE_9_TEXT': `Our proctoring feature allows for fair and secure assessments. Starting with the basic copy-paste lock, 
        and question-answer randomization, to audiovisual capture and backlog monitoring to prevent cheating.`,
    'FEATURE_10_TITLE': 'Systems Intelligence (AI)',
    'FEATURE_10_TEXT': `Empower your recruiters and assessors with automated insights about candidates, to save time while being 
        confident in decision-making. Automated scoring and ranking, and data compatible with your people analytics initiatives.`,
    'FEATURE_11_TITLE': 'Strengthen Your ATS',
    'FEATURE_11_TEXT': `For fast, easy recruiting we integrate into leading ATS platforms. We strongly believe in a recruitment 
        experience with the ATS/CRM at the core, supported by our integration. `,
    'FEATURE_12_TITLE': 'Full Support',
    'FEATURE_12_TEXT': 'We offer full support for both candidates and recruiters. Chat with us or email help@astrnt.co. Our friendly team will support your every need.',
    'FEATURE_13_TITLE': 'All Devices, High Accessibility',
    'FEATURE_13_TEXT': 'We ensure the highest possible candidate participation by being available on Android, iOS and web. We even work in poor internet conditions.',
    'FEATURE_14_TITLE': 'Assessment Solutions (Services & Partners)',
    'FEATURE_14_TEXT': 'Work with our partners and specialists to create the right competency-based assessment content for your organisation. We are with you all the way.',
    'FEATURE_15_TITLE': 'Connecting Your Tools',
    'FEATURE_15_TEXT': 'Ensure your ATS remains your central dashboard. We integrate with leading ATS providers, and are adding more. Speak with us about your ATS.',
    // End-feature

    //Start supportListNew
  'SUPPORT_CARD_LABEL_1':'Support is included in all packages',
  'SUPPORT_CARD_LABEL_2':`Candidate convenience with desktop, iOS or Android`,
  'SUPPORT_CARD_LABEL_3':`Try for free, cancel anytime`,

   //End supportListNew

  //Start DemonstrationPageNew
  'DEMO_CARD_TITLE':`It’s demo time`,
  'DEMO_CARD_TEXT':`Let us show you how our powerful platform can help you hire faster and accelerate your organisation’s workflow.`,
  'DEMO_CARD_TITLE_FORM':`Tell us a bit about yourself, and we’ll tell you a lot more about us.`,


  //FORM
  'DEMO_FORM_LABEL_1':`Name of Company/Organisation *`,
  'DEMO_FORM_LABEL_2':`Email address *`,
  'DEMO_FORM_LABEL_3':`Name *`,
  'DEMO_FORM_LABEL_4':`Mobile Phone *`,
  'DEMO_FORM_LABEL_5':`Prefered Date`,
  'DEMO_FORM_LABEL_6':`Prefered Time`,
  'DEMO_FORM_LABEL_7':`Timezone`,
  'DEMO_FORM_LABEL_8':`How do you hope ASTRNT can help achieve your goals? *`,

  'DEMO_FORM_PLACEHOLDER_7':`Select Timezone`,

  //End DemonstrationPageNew

  //Start TrainingPage
  'TRAINING_CARD_TITLE':`Upgrade your team’s skills`,
  'TRAINING_CARD_TEXT':`Our free training sessions are available for your team. Learn new technologies, best practices and more. Register your team below and one of our representatives will contact you to schedule a time. Training begins in August 2022.`,

  //FORM
  'TRAINING_FORM_LABEL_1':`Name of Company/Organisation *`,
  'TRAINING_FORM_LABEL_2':`Company Email Address *`,
  'TRAINING_FORM_LABEL_3':`Name, Point of Contact *`,
  'TRAINING_FORM_LABEL_4':`Mobile Phone, Point of Contact *`,
  'TRAINING_FORM_LABEL_5':`Which Topics Are Of Most Interest? *`,
  'TRAINING_FORM_LABEL_6':`Why does your team need to upgrade their skills? *`,
  'TRAINING_FORM_LABEL_7':`Training is online, however in person is available in Jabodetabek. What is your preferred method of delivery? *`,

  'TRAINING_FORM_PLACEHOLDER_6':`Type your answer here...`,
  'TRAINING_FORM_PLACEHOLDER_7':`Type your answer here...`,

  //End TrainingPage

  //new astrnt.co phase 2 homepage
  
  'SIGNUP_TODAY':`Sign-Up Today`,
  'EXPERT_SOLUTION':`Expert Solutions For<br/>Everything You Need`,
  'OUR_EXPERT_TEAM':`Our expert team serves employers, universities, and talent, leveraging our science-based holistic assessment platform.`,
  'HIRING_TRANSFORMED':`Hiring processes, transformed`,
  'TALENT_MANAGEMENT_CERT':`Talent management & certification`,
  'UNIV_ADMS_MODERN':`University admissions, modernised`,
  'UNIV_CAREER_EMPOWERED':`University career centres, empowered`,
  'HOLISTIC_COMP_SCIENCE':`"Holistic, Competency<br/>& Science-Based"`,
  'USE_THE_RIGHT_TOOLS':`Use the right tools, the right way, end-to-end, and within your budget.`,
  'NAVY_LIST_1':`67 Competencies Framework`,
  'NAVY_LIST_2':`Automated Structured Interviews`,
  'NAVY_LIST_3':`Cognitive Assessment`,
  'NAVY_LIST_4':`Big-5 Personality Assessment`,
  'NAVY_LIST_5':`Skills Assessments (Domain Experts]`,
  'NAVY_LIST_6':`Realistic Job Previews (inc In-Tray)`,
  'NAVY_LIST_7':`Virtual Work Experience Programs`,
  'NAVY_LIST_8':`Career Interest Assessments`,
  'OUR_ASSESS_PRODUCTS':`Our assessment products from Career Compass Assessment, Value Reflect Assessment, Creative Thinking Assessment, Cognitive Test, to Personality Questionnaire.`,
  'SHEEREN_TEXT':`"As a psychometrician, I lead our dynamic team to fulfill your needs in every budget tier. Witness your processes transformation into competency-driven, science-fortified, objective realms of speed. All orchestrated by our cutting-edge automation, including asynchronous video interviews."`,
  'SHEEREN_POSITION':`"Head of People Data & Decision<br/>Innovation at ASTRNT"`,
  'CUST_END_TO_END_EXP':`Customisable, End-To-End Experience`,
  'EASY_APPLY_WITH_VERIFY':`Easy Apply with Verification, Complete & Comprehensive Tools, On-Demand, Any Device, Low-Bandwith Friendly, Fast, Accurate,Reviewing & Decision`,
  'REAL_BUSINESS_IMPACT':`Real Business Impact`,
  'OPTIMISE_TIME_METRICS':`Optimise time, quality & cost metrics by giving applicant candidates the easiest way to prove their competencies & motivations. Eliminate unnecessary interviews, confidently shortlisting fast.`,
  'DELIVERING_SUCCESS':`Delivering Success In Many Ways`,
  'NEXT_GEN_ASSESS_CENTER':`NextGen Assessment Center & All Year Hiring (inc RPO) Success`,
  'SAMPOERNA_SAW':`Sampoerna saw opportunity to optimise their entire assessment center and recruitment workflow. Astrnt helped deliver this by bundling skill, psychometric assessment and interview automation into single candidate experience.`,
  'TALENT_MANAGE_NEXTGEN_ASSESS':`Talent Management with NextGen Assessment Center Method`,
  'TELKOM_AKSES_AIMED':`Telkom Akses aimed to enhance employee quality to meet the industry's high demands and dynamic changes through talent mapping. They began with 700 managers across Indonesia, followed by 1,300 employees, implementing the NextGen Assessment Center method to identify future leaders and elevate workforce quality.`,
  'NUR_REVIEW':`Integrating and collaborating with Astrnt for a strong digital recruitment strategy, was the smartest decision ever. We considered both candidate experience and process efficiency, to make sure things are scalable in the long run.`,
  'WINSTON_REVIEW':`ASTRNT platform gave us a holistic view of each candidate to be surgically precise at selecting the very best talents for our NUS cohorts.`,
  'ELEVATE_YOUR_PLATFORM':`Elevate Your Platform with ASTRNT API`,
  'WE_PARTNER_WITH_ATS':`We partner with ATS, HRIS, Jobs boards and other talent platforms to create unique win-win revenue generating opportunities, anywhere in the world. Our expert team is ready to ideate.`,
  'EXPLORE_OPPORTUNITIES':`Explore Opportunities`,
  'UP_RUNNING_4_STEPS':`Up & Running<br/>In <b>4 Easy Steps</b>`,
  'START_FREE_ASTRNT_ACCOUNT_TODAY':`Start a free ASTRNT account today to access comprehensive tools that make your processes faster and accessible to all candidates, regardless of bandwidth. Share with peers for fast, accurate review and decision-making.`,
  'SEE_OUR_PLANS':`See Our Plans`,
  'TALENT_ACQUISITION':`Talent Acquisitions`,
  'TALENT_MANAGEMENT':`Talent Management`,
  'UNIVERSITY_ADMISSION':`University Admissions`,
  'POWERED_BY_ASTRNT':`Powered by ASTRNT`,
  'RESOURCES':`Resources`,

  //End new astrnt.co phase 2 homepage

  //solution dropdown
  
  'TALENT_ACQUISITION_DESC':`Hire fast with speed and accuracy with our async interview technology`,
  'TALENT_MANAGEMENT_DESC':`Assess your workforce by simplify complex processes and saving both time and money.`,
  'UNIVERSITY_ADMISSION_DESC':`Measure candidate skills and potential while saving valuable faculty time.`,
  'POWERED_BY_ASTRNT_DESC':`Elevate you platform with our technology, go to market and deliver fast.`,

  //solution dropdown

  'CONSULT_WITH_OUR_EXPERT':`Consult with Our Assessment Experts`,

  // SOL TALENT ACQUI
  'YOUR_HIRING_MANAGER':`Your Hiring Managers Will Thank You`,
  'EXCEED_EXPECTATION':`Exceed expectations and do more with less`,
  'ASTRNT_ADVANCED_TOOLS':`ASTRNT's advanced tools help you delight hiring managers, with speed and accuracy. Confidently select fast and match talent across all roles, from blue-collar to professional, to graduates. Transform your hiring process and achieve unparalleled results.`,
  'I_WOULD_SAY':`I would say this is the smartest decision ever by integrating and collaborating with ASTRNT to embark on a strong digital recruitment strategy.`,
  'WE_POWER_YOUR_JOB':`We Power Your Job Profiling Focus On Decision Science`,
  'UNDERSTANDING_COMPETENCIES':`Understanding competencies is crucial to hiring. Identify ideal candidates by focusing on key evaluation aspects, conducting skill-based assessments for blue-collar positions, identifying potential in recent graduates, and finding the best fit for professional roles. Our diverse assessment solutions streamline hiring, effectively matching candidates to roles, and saving time and resources while improving outcomes.`,
  'HOLISTIC_ASSESS_GLOBAL':`Holistic Assessment Applied Globally`,
  'UNLOCK_NEW_GEO':`Unlock new geographies and new talent pools with ease. Adopting ASTRNT for talent acquisition gives hiring managers access to deep competency and motivational data of candidates without needing to engage in lengthy selection and interview processes. And do this while fostering a more high-performing workplace.`,
  'FAST_DESICION_APPROVAL':`Fast Decisions & Approvals User-Friendly Dashboard`,
  'ENHANCE_PRODUCT':`Enhance productivity with expedited decision-making and informed choices.`,
  'OUR_ASYNC_TECH':`Our asynchronous technology allows candidates to complete assessments at their convenience, reducing time-to-hire. This efficient process fosters transparency and trust, enabling managers to quickly identify top candidates and build stronger teams. This approach will lead to a more productive and cohesive work environment.`,

  // SOL TALENT management
  'NEXT_GEN_ASSESS_CENTER_MODEL':`NextGen Assesment Centers Reimagining The Model`,
  'NEXT_GEN_ASSESS_STREAMLINES':`The NextGen Assessment Center streamlines talent management with its asynchronous technology, simplifying complex processes and saving both time and money. This practical and efficient solution enhances the hiring experience, making it smoother and faster for all parties involved.`,
  'THE_ASSESSMENT_RESULT_CONDUCTED':`The assessment results conducted by the NextGen Assessment Center method are identical to those of traditional assessment centers, but achieved much faster.`,
  'RELIABLE_FLEXIBLE_COST':`Reliable, Flexible Cost-Effective & Scalable`,
  'EXPERIENCE_THE_BENEFITS':`Experience the benefits of our NextGen Assessment Center: reliability, flexibility, and cost-effectiveness. Our method efficiently evaluates hundreds of employees within a month, ensuring a seamless and efficient hiring process.`,
  'SCALE_UP_YOUR_IMPACT':`Scale Up Your Impact With Digitalisation`,
  'TECHNOLOGY_DRIVEN_TALENT':`Technology-driven talent management method is highly scalable, eliminating the need for in-person assessments. This allows businesses using NextGen Assessment Center to efficiently evaluate a large number of employees, saving time and resources while connecting with candidates globally.`,
  'ELEVATE_YOUR_ASSESSMENT':`Elevate Your Assessment Center WIth NextGen Model`,
  'BY_INTERGRATING_LATEST_TECH':`By integrating the latest technology, we enhance existing Assessment Center’s delivery, accuracy, speed, and assessment quality. This modernization ensures efficient processes, delivering better insights and an improved candidate experience. Lets elevate your Assessment Center`,

  // SOL univ admission
  'TRANSFORM_YOUR_ADMISSION':`Transform your admissions and assessments with our on-demand, timed video and written tests. These tools provide flexible, efficient evaluations, ensuring accurate measurement of candidates' skills and potential. Streamline your process and enhance selection quality with our innovative solutions.`,
  'GET_HOLISTIC_FAIR':`Get a Holistic View, Make Fair Decisions`,
  'AUTOMATED_ASSESS_BUILD_MOBILE':`Automated assessment built on mobile first in mind, assists in handling mass amounts of test. Its efficient for the admissions team, and preferred by most millennials who prefer to engage life through their mobile devices.`,
  'SAVE_TIME_ENGAGE_FAST':`Save Time, Engage Preferred Fast`,
  'ELIMINATE_TIME_CONSUMING_REPETITIVE':`Eliminate time-consuming and repetitive tasks to allow yourself more time to engage with applicants in a meaningful way. By streamlining administrative processes, you can dedicate your efforts to understanding each candidate's unique qualities and potential. This focused approach enhances the quality of your interactions and ensures a more thorough and insightful evaluation of applicants.`,
  'ENSURE_INTEGRITY':`Ensured Integrity`,
  'ASTRNT_UPHOLDS':`ASTRNT upholds assessment integrity by implementing essential proctoring features, including disabling copy-paste, blocking tab openings, and monitoring candidates through system logs and video interviews.`,

  // SOL powered by astrnt
  'ELEVATE_YOUR_PLATFORM_WITH_OUR_TECH':`Elevate Your Platform With our Technology`,
  'WE_EMPOWER_JOB_BOARDS':`We empower job boards, ATS, HRIS, and assessment platforms to offer asynchronous video functionality without the complexities of building and maintaining it. ASTRNT provides collaborative or white-label solutions, serving as your trusted technology partner.`,
  'WIN_WIN_SOLUTION':`Win-Win Solutions`,
  'PARTNERING_WITH_ATS_HRIS':`Partnering with ATS, HRIS, job boards, and talent platforms, we create global revenue opportunities. Our expert team develops innovative solutions, focusing on helping SMEs and start-ups enhance their talent acquisition and management processes.`,
  'OUR_ADVANCED_API':`Our advanced API technology enables seamless integration, offering features like asynchronous video interviews, on-demand learning, skill verification, and data analysis. Our solutions reduces development costs, saves time to get you to market fast. With easy integration, you'll have asynchronous video capabilities in hours, allowing your team to focus on growth.`,
  'WE_MAKE_IT_EASY_FOR_YOU':`We Make It Easy For You`,
  'WITH_OUR_PLATFORM_YOU_CAN_CREATE':`With our platform, you can create customized asynchronous video experiences. Our APIs and SDKs guarantee flawless video delivery on all major browsers and devices, boosting accessibility and user engagement. Whether your goal is to enhance customer interactions, simplify communication, or improve educational content, our technology equips you for success.`,
}