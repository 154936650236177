// @flow
import initializers from '../dependencies/initializers'
import axios from 'axios'
export function usersCheckEmailApi(email: string) {
	return axios({
		url: initializers.recruiter_host+'/users/check-email',
		timeout: 20000,
		method: 'post',
		responseType: 'json',
		// headers: {'X-Requested-With': 'XMLHttpRequest'},
		data: {
			email
		}
	})
}

export async function usersSaveDataApi(data: Object) {

		return await axios({
			url: initializers.recruiter_host+'/users',
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			// headers: {'X-Requested-With': 'XMLHttpRequest'},
			data
		})
		.then(response => ({ response }))
		.catch(error => ({ error }))
		

}

export function usersSaveDataApiGreenHouse(data: Object) {
	return axios({
		url: initializers.recruiter_host+'/users/greenhouse',
		timeout: 20000,
		method: 'post',
		responseType: 'json',
		// headers: {'X-Requested-With': 'XMLHttpRequest'},
		data
	})
}

export function usersResendVerificationEmailApi(email: string) {
	return axios({
		url: initializers.recruiter_host+'/users/resend_verification_email',
		timeout: 20000,
		method: 'post',
		responseType: 'json',
		headers: {'X-Requested-With': 'XMLHttpRequest'},
		data: {
			email: email
		}
	})
}
