import configurations from '../../../helpers/misc/configurations'

const validate = values => {
	const errors = {}

	if (!values.email) {
		errors.email = `Email can't be blank`
	} else {
		if (!configurations.emailRegex.test(values.email)) {
			errors.email = `Invalid email`
		}
	}

	if (!values.fullname) {
		errors.fullname = `Full Name can't be blank`
	}

	if (!values.password) {
		errors.password = `Password can't be blank`
	} else {
		var pattern = /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/;
		if (values.password.length < 8 || !pattern.test(values.password)) {
			errors.password = `Your password must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters.`;
		}
		// let hasNumber = /^.*(?=.*\d).*$/.test(values.password)
		// let hasUpper = /^.*(?=.*[A-Z]).*$/.test(values.password)
		// let hasLower = /^.*(?=.*[a-z]).*$/.test(values.password)

		// let errorPassword = []
		// if(values.password.length < 8){
		// 	errorPassword.push('at least 8 characters')
		// }

		// if(!hasNumber){
		// 	errorPassword.push('a number')
		// }

		// if(!hasUpper){
		// 	errorPassword.push('an uppercase')
		// }

		// if(!hasLower){
		// 	errorPassword.push('a lowercase')
		// }

		// if(errorPassword.length>0){
		// 	let errorPolicy = 'Your password must contain'
		// 	for (let index = 0; index < errorPassword.length; index++) {
		// 		let message = ', ' + errorPassword[index]
		// 		const lastIndex = errorPassword.length -1
		// 		if(index == 0 || errorPassword.length == 1){
		// 			message  = ' ' + errorPassword[index]
		// 		}else if (index == lastIndex){
		// 			message  = ' and ' + errorPassword[index]
		// 		}

		// 		errorPolicy += message
		// 	}

		// 	errors.password = errorPolicy + '.'
		// }
	}

	if (!values.company_name) {
		errors.company_name = `Company Name can't be blank`
	} else{
		if(values.company_name.length > 65){
			errors.company_name = `Company Name cannot more than 65 characters`
		}
	}

	if (!values.total_roles_description) {
		errors.total_roles_description = `Please select the number of roles`
	}

	if (!values.assist_description) {
		errors.assist_description = `Description can't be blank`
	}

	if (!values.mobile_number) {
		errors.mobile_number = `Mobile Number can't be blank`
	} else{
		if(values.mobile_number.length > 13){
			errors.mobile_number = `Mobile Number cannot more than 13 characters`
		}

		if(values.mobile_number.length < 10){
			errors.mobile_number = `Mobile Number cannot less than 10 characters`
		}
	}

    if (!values.harvest_api_key) {
		errors.harvest_api_key = `API Key can't be blank`
	}

	return errors
}

export default validate
