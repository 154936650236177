// @flow

import { put, select, call, delay } from 'redux-saga/effects'

import { push } from "react-router-redux";

import {
	change,
	startAsyncValidation,
	stopAsyncValidation
} from 'redux-form'

import {
	usersSaveDataApi,
	usersCheckEmailApi,
	usersResendVerificationEmailApi,
	usersSaveDataApiGreenHouse,
} from '../apis/users'

import initializers from '../dependencies/initializers'

import { getForm } from '../selectors'
import configurations from '../helpers/misc/configurations'

export function* usersValidateEmailOnUserRegisterWizardForm({email}: Object): Generator<*, *, *> {
	const errors: Object = {email: '<i style="font-size: 12px">This email is already registered. Would you like to <a href='+initializers.recruiter_host+'/auth/login style="color: #a94442; text-decoration: underline">login?</a></i>'}

	try {
		yield put(change('UserRegisterWizardForm', 'email', email, true, true))

		if (configurations.emailRegex.test(email)) {
			yield put(startAsyncValidation('UserRegisterWizardForm'))

			yield delay(1000)

			const response: Object = yield call(
				usersCheckEmailApi,
				email
			)

			if (response.status === 200) {
				if (response.data.message === 'new_email') {
					yield put(stopAsyncValidation('UserRegisterWizardForm', {}))
				} else {
					yield put(stopAsyncValidation('UserRegisterWizardForm', errors))
				}
			} else if (response.status === 422) {
				yield put(stopAsyncValidation('UserRegisterWizardForm', errors))
			}
		}
        console.log(configurations.emailRegex.test(email))
	} catch (error) {

		yield put(stopAsyncValidation('UserRegisterWizardForm', errors))
	}
}

export function* usersSaveData({formName}: Object): Generator<*, *, *> {
	try {
		// yield put(startAsyncValidation('UserRegisterWizardForm'))

		const form: Object = yield select(getForm)
		const {response,error} = yield call(
			usersSaveDataApi,
			form[formName].values
		)
		console.log(response)
		
		if (response) {
			yield put(stopAsyncValidation('UserRegisterWizardForm', {}))

			yield put({
				type: 'USERS_ADD_NAVIGATION_PAGE_SUCCESS',
				page: 2
			})

			yield put({
				type: 'USER_TEMP_EMAIL',
				email: form[formName].values.email
			})

			yield put(push(`/registered`));
		} else {
			if(error.response.data.message) {
				let errors = {}
				const messages = error.response.data.message
				if(messages.fullname) {
					errors.fullname = messages.fullname[0]
				}
				if(messages.company_name) {
					errors.company_name = messages.company_name[0]
				}
				yield put(stopAsyncValidation(formName, errors))
			} else {
				throw error
			}
		}
	} catch (error) {
		const message = error.response.data.message
		const errors: Object = {email: '<i style="font-size: 12px">This email is already registered. Would you like to <a href='+initializers.recruiter_host+'/auth/login style="color: #a94442; text-decoration: underline">login?</a></i>'}
		// console.log(message)
		yield put(stopAsyncValidation(formName, errors))
	}
}

export function* usersSaveDataGreenHouse({formName}: Object): Generator<*, *, *> {
	try {
		yield put(startAsyncValidation('UserRegisterWizardFormFirstPageGreenHouse'))

		const form: Object = yield select(getForm)
		console.log(form[formName])
		const response: Object = yield call(
			usersSaveDataApiGreenHouse,
			form[formName].values
		)

		if (response.status >= 200 && response.status < 300) {
			yield put(stopAsyncValidation('UserRegisterWizardFormFirstPageGreenHouse', {}))

			yield put({
				type: 'USERS_ADD_NAVIGATION_PAGE_SUCCESS',
				page: 2
			})

			yield put({
				type: 'USER_TEMP_EMAIL',
				email: form[formName].values.email
			})
		} else {
			throw response
		}
	} catch (error) {
		yield put(stopAsyncValidation(formName, {}))
	}
}

export function* usersResendVerificationEmail({ email }: Object): Generator<*, *, *> {
	try {
		const response: Object = yield call(
			usersResendVerificationEmailApi,
			email
		)

		if (response.status >= 200 && response.status < 300) {
			yield put({
				type: 'GLOBAL_SHOW_NOTIFICATION_REQUEST',
				title: 'Success',
				message: 'Resend email verification success!',
				position: 'bl',
				level: 'success'
			})
		} else {
			throw response
		}
	} catch(error) {
		console.error(error)
	}
}
