import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import imgFeatureList1 from '../../images/public/users.png'
import imgFeatureList2 from '../../images/public/star.png'
import imgFeatureList3 from '../../images/public/badge.png'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import BlogListNew from '../partials/BlogListNew.jsx'
import SupportListNew from '../partials/SupportListNew.jsx'
import BannerDemoNew from '../partials/BannerDemoNew.jsx'

import {getOSInfo , translate } from '../../helpers/global'
import CallToActRegister from '../partials/CallToActRegister.jsx'
import BlogList from '../partials/BlogList.jsx'

import homeIMG from '../../images/solution-1-big.png'

import personReview from '../../images/person_mbsb_2.png'
import logoReview from '../../images/logo_mbsb.png'

import imgSec1 from '../../images/solution-1-sec1.png'
import imgSec2 from '../../images/solution-1-sec2.png'
import imgSec3 from '../../images/solution-1-sec3.png'

import travelokaLogo from '../../images/logo/traveloka.png'
import philipLogo from '../../images/logo/philip.png'
import bcaLogo from '../../images/logo/bca.png'
import mbsbLogo from '../../images/logo/mbsb.png'
import nusLogo from '../../images/logo/nus.png'
import shopeeLogo from '../../images/logo/shopee.png'
import bniLogo from '../../images/logo/bni.png'
import infocommLogo from '../../images/logo/infocomm.png'
import darwinboxLogo from '../../images/logo/darwinbox.png'
import homecreditLogo from '../../images/logo/homecredit.png'

import teamtailorLogo from '../../images/logo/teamtailor.png'
import workableLogo from '../../images/logo/workable.png'
import pulsifiLogo from '../../images/logo/pulsifi.png'
import talentdatalabsLogo from '../../images/logo/talentdatalabs.png'
import podiumLogo from '../../images/logo/podium.png'
import greenhouseLogo from '../../images/logo/greenhouse.png'

import imgSeamless from '../../images/seamless.png'

import imgCard1 from '../../images/graduate-1.png'
import imgCard2 from '../../images/graduate-2.png'
import imgCard3 from '../../images/graduate-3.png'

import Cookies from 'js-cookie';
import { usersGetDetailAsync } from '../../actions/users.js'

class ArticlesGraduateHiringNew extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

  render() {

    const {
      globalGetLanguages,
      user
    } = this.props
    const renderHTML = (rawHTML: string) => React.createElement("span", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return (
			<div id="homepage">
        <HeaderNewClear isOnStatusFor="is-on" />
        <div className="section-top-solution relative double-triangle triangle-left" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='solution-top-box-hanging aqua'>
                  <h1 className='m-t-0 m-b-16'>
                    {translate(globalGetLanguages,'YOUR_HIRING_MANAGER')}
                  </h1>
                  <span className='m-b-16'>{translate(globalGetLanguages,'EXCEED_EXPECTATION')}</span>
                  <p className='m-b-0'>{translate(globalGetLanguages,'ASTRNT_ADVANCED_TOOLS')}</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>{translate(globalGetLanguages,'CONSULT_WITH_OUR_EXPERT')}</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right flex-container flex-end align-items-center'>
              <div className='horizontal-item--center'>
                <img className='img-100 img-solution-aqua' src={homeIMG} />
              </div>
            </div>
          </section>
          <div className='solution-back-top aqua desktop-hide' style={globalGetLanguages == 'id' ? {height: '777px'} : {}}></div>
        </div>
        <div className="section-white" style={{overflow: 'hidden'}}>
          <section>
            <div className="text-center review-slider solution">
              <p>“{translate(globalGetLanguages,'I_WOULD_SAY')}”</p>
              <div className='horizontal-item--between source-div'>
                <div className='vertical-item--center'>
                  <img className='person-img' src={personReview} />
                  <div className='flex-column'>
                    <h5>Nur Syahada Taufek</h5>
                    <h6>Talent Acquisition & Employer Branding<br/>Manager, MSBB Bank Berhad</h6>
                  </div>
                </div>
                <div className='vertical-item--center'>
                  <img className='img-100 logo-review' src={logoReview} />
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="section-white relative double-triangle triangle-right sol-sec1-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
          <div className='flex-item-left horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={imgSec1} />
              </div>
            </div>
            <div className='flex-item-right'>
              <div className='vertical-item--center'>
                <div className='red-box-hanging-right'>
                  <h1 className='m-t-0 m-b-16'>
                    {translate(globalGetLanguages,'WE_POWER_YOUR_JOB')}
                  </h1>
                  <p className='m-b-0'>
                  {translate(globalGetLanguages,'UNDERSTANDING_COMPETENCIES')}
                  </p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>{translate(globalGetLanguages,'CONSULT_WITH_OUR_EXPERT')}</a>
                </div>
              </div>
            </div>
          </section>
          <div className='red-back-feature-right for-1 desktop-hide' style={globalGetLanguages == 'id' ? {height: '735px'} : {}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-left disable-small-triangle sol-sec2-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='navy-box-hanging'>
                  <h1 className='m-t-0 m-b-16' style={{color: 'white'}}>
                    {translate(globalGetLanguages,'HOLISTIC_ASSESS_GLOBAL')}
                  </h1>
                  <p className='m-b-0'>
                  {translate(globalGetLanguages,'UNLOCK_NEW_GEO')}
                  </p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>{translate(globalGetLanguages,'CONSULT_WITH_OUR_EXPERT')}</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={imgSec2} />
              </div>
            </div>
          </section>
          <div className='navy-back-feature-left for-1 desktop-hide' style={globalGetLanguages == 'id' ? {height: '581px'} : {}}></div>
        </div>
        <div className="section-white relative double-triangle triangle-right sol-sec1-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
          <div className='flex-item-left horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={imgSec3} />
              </div>
            </div>
            <div className='flex-item-right'>
              <div className='vertical-item--center'>
                <div className='purple-box-hanging-right'>
                  <h1 className='m-t-0 m-b-16'>
                    {translate(globalGetLanguages,'FAST_DESICION_APPROVAL')}
                  </h1>
                  <span className='m-b-16'>
                  {translate(globalGetLanguages,'ENHANCE_PRODUCT')}
                  </span>
                  <p className='m-b-0'>
                  {translate(globalGetLanguages,'OUR_ASYNC_TECH')}
                  </p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>{translate(globalGetLanguages,'CONSULT_WITH_OUR_EXPERT')}</a>
                </div>
              </div>
            </div>
          </section>
          <div className='purple-back-feature-right for-1 desktop-hide' style={globalGetLanguages == 'id' ? {height: '778px'} : {}}></div>
        </div>
        <FooterNew />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
    globalGetLanguages:state.globalGetLanguages,
    user: state.user
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    usersGetDetailAsync: (user) => dispatch(usersGetDetailAsync(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesGraduateHiringNew)
