// @flow

import React from 'react'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { Map } from 'immutable'
import imgAstronautLogo from '../../images/logo-black.svg'
import UserRegisterWizardFormFirstPageGreenHouse from './users/UserRegisterWizardFormFirstPageGreenHouse'
import UserRegisterWizardFormSecondPageNew from './users/UserRegisterWizardFormSecondPageNew'
import UserRegisterWizardFormThirdPageNew from './users/UserRegisterWizardFormThirdPageNew'
import initializers from '../../dependencies/initializers'
import { usersAddNavigationPage } from '../../actions/users'
import { globalBlockingTransition } from '../../helpers/global'

import HeaderGreenHouse from '../partials/HeaderGreenHouse.jsx'
import FooterNew from '../partials/FooterNew.jsx'

import AstrntFavicon from '../../styles/img/favicon.png'

type Props = {
	resetForm: Function,
	usersAddNavigationPage: Function,
	usersNavigationData: Map<string, any>
};

type State = {
	childrenComponent: ?Object,
	unblockTransition: Function,
	cachedUsersNavigationDataPage: string
};

class UserRegisterWizardFormGreenHouse extends React.Component<Props, State> {
	constructor(props) {
		super(props)

		this.state = {
			childrenComponent: null,
			unblockTransition: () => {},
			cachedUsersNavigationDataPage: ''
		}
	}

	componentDidMount() {
		// const {
		// 	resetForm,
		// 	usersAddNavigationPage
		// } = this.props

		const titleElement: Object = document.getElementsByTagName('title')

		titleElement[0].text = 'Astronaut Technologies'

		// const unblockTransition: Function = globalBlockingTransition(() => {
		// 	resetForm('UserRegisterWizardForm')
		// 	usersAddNavigationPage(1)

		// 	return 'Data is not saved! Are you sure you want to leave?'
		// })

		// this.setState({unblockTransition})
	}

	componentWillUnmount() {
		console.log('handled in children page')
		// this.state.unblockTransition()
		const {
			resetForm,
			usersAddNavigationPage
		} = this.props

		resetForm('UserRegisterWizardForm')
		usersAddNavigationPage(1)
	}

	handleLoginLink(e: Object): void {
		e.preventDefault()
			window.location = initializers.recruiter_host+'/auth/login'
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const {
			usersNavigationData,
			usersAddNavigationPage
		} = nextProps

		if (usersNavigationData.get('page') !== prevState.cachedUsersNavigationDataPage) {
			let page: number = usersNavigationData.get('page')

			const nextPage: Function = () => {
				usersAddNavigationPage(page + 1)
			}

			const previousPage: Function = () => {
				usersAddNavigationPage(page - 1)
			}

			switch(page) {
				case 1:
					return {
						cachedUsersNavigationDataPage: page,
						childrenComponent: <UserRegisterWizardFormFirstPageGreenHouse
							previousPage={previousPage}
							onSubmit={nextPage} />
					}
				case 2:
					return {
						cachedUsersNavigationDataPage: page,
						childrenComponent: <UserRegisterWizardFormThirdPageNew />
					}
				default:
					return {
						cachedUsersNavigationDataPage: page,
						childrenComponent: <UserRegisterWizardFormFirstPageGreenHouse
							previousPage={previousPage}
							onSubmit={nextPage} />
					}
			}
		}

		return null
	}

	render() {
    return (
			<div id="register">
	        <HeaderGreenHouse />
	        <div className="pad-top" />
	        <section className="sec-reg">
	          <ul className="lay-reg greenhouse">
	            <li>
	              <div className="box-reg">
	                {this.state.childrenComponent}
	              </div>
	            </li>
	          </ul>
	        </section>
	        <FooterNew />
	      </div>
    )
	}
}

const mapStateToProps = (state) => {
	return {
		usersNavigationData: state.usersNavigationData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		resetForm: (formName) => dispatch(reset(formName)),
		usersAddNavigationPage: (page) => dispatch(usersAddNavigationPage(page))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRegisterWizardFormGreenHouse)
