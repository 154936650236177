// @flow

import React from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'

import AstrntFavicon from '../../../styles/img/favicon.png'

import {
	Field,
	reduxForm
} from 'redux-form'

import RenderInput from './RenderInput'

import validateUserForGreenHouse from './validateUserForGreenHouse'

import $ from 'jquery'
import {Prompt} from 'react-router-dom'
import { usersValidateEmailOnUserRegisterWizardFormRequest, usersSaveDataRequestGreenHouse } from '../../../actions/users'

type Props = {
	pristine: boolean,
	submitting: boolean,
	handleSubmit: Function,
	asyncValidating: boolean,
	usersValidateEmailOnUserRegisterWizardFormRequest: Function,
	usersSaveDataRequestGreenHouse: Function
};

const normalizeEmail = (value, previousValue) => {
	return value.trim()
}

class UserRegisterWizardFormFirstPageGreenHouse extends React.Component<Props> {
	constructor(props) {
    super(props)

    this.state = {
      isBlocking: false,
      hasUpper:false,
      hasLower:false,
      hasNumber:false,
      hasEightChar:false
    }

		bindAll(this, [
      'handleEmailChange',
      'handleSaveData',
      'handleChange',
      'onUnload',
      'checkPasswordPolicy'
		])
	}

	handleEmailChange(e) {
    this.handleChange(e)
		this.props.usersValidateEmailOnUserRegisterWizardFormRequest(e.target.value.trim())
  }

  onUnload(e) {
    e.preventDefault()
    e.returnValue = ''
  }

  componentDidUpdate() {
    const { isBlocking } = this.state
    if(isBlocking) {
      window.addEventListener('beforeunload', this.onUnload)
    } else {
      window.removeEventListener('beforeunload', this.onUnload)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  handleChange(e) {
    if(e.target.name == 'password'){
      this.checkPasswordPolicy(e.target.value)
    }

    var isBlocking = false
    $('form input').each(function() {
      if(this.value !== '') {
        isBlocking = true;
        return false;
      }
    })
    this.setState({isBlocking: isBlocking})

  }

  handleSaveData(e) {
		this.props.usersSaveDataRequestGreenHouse('UserRegisterWizardFormFirstPageGreenHouse')
  }

  checkPasswordPolicy(val){
    let hasUpper = /^.*(?=.*[A-Z]).*$/.test(val)
    let hasLower = /^.*(?=.*[a-z]).*$/.test(val)
    let hasNumber = /^.*(?=.*\d).*$/.test(val)
    let hasEightChar = val.length >= 8 ? true : false

    this.setState({hasLower:hasLower, hasUpper: hasUpper, hasNumber: hasNumber, hasEightChar: hasEightChar})
  }

  render() {
  	const {
  		pristine,
  		submitting,
  		handleSubmit,
  		asyncValidating
  	} = this.props

    return(
    	<form onSubmit={handleSubmit(this.handleSaveData)}>
        <Prompt
          when={this.state.isBlocking}
          message={location => `Data is not saved! Are you sure you want to leave?`}
        />
    		<div className="tit-reg">Create Your <img src={AstrntFavicon} /> Account</div>
        <ul className="the-form">
          <li>
            <Field
            component={RenderInput}
            name="email"
            onChange={this.handleEmailChange}
            props={{
              isValidatingEmail: asyncValidating
            }}
            label="Email"
            normalize={normalizeEmail} />
          </li>
          <li>
            <Field
            component={RenderInput}
            name="fullname"
            onChange={this.handleChange}
            label="Full Name" />
          </li>
          <li>
            <Field
            component={RenderInput}
            name="mobile_number"
            onChange={this.handleChange}
            type="number"
            label="Mobile Number"
            className="no-spinner" />
          </li>
          <li style={{position: 'relative'}} className="input-tool">
            <Field
            component={RenderInput}
            name="password"
            onChange={this.handleChange}
            type="password"
            PassReqMobile={<span className="pass-req-mobile">at least 8 characters, one number, uppercase and lowercase letters.</span>}
            label="Password" />
            <span class="tooltiptext">
              Your password must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters.
              <span class={`pass-req ${ this.state.hasEightChar ? 'active':''}`}><i class="far fa-check-circle"></i>at least 8 characters</span>
              <span class={`pass-req ${ this.state.hasNumber ? 'active':''}`}><i class="far fa-check-circle"></i>contain a number</span>
              <span class={`pass-req ${ this.state.hasUpper ? 'active':''}`}><i class="far fa-check-circle"></i>contain an uppercase</span>
              <span class={`pass-req ${ this.state.hasLower ? 'active':''}`}><i class="far fa-check-circle"></i>contain a lowercase</span>
            </span>
          </li>
          <li>
            <Field
            component={RenderInput}
            name="company_name"
            onChange={this.handleChange}
            label="Company Name" />
          </li>
          <li>
            <Field
            component={RenderInput}
            name="harvest_api_key"
            onChange={this.handleChange}
            label="Harvest API Key" />
          </li>
          <li>
            <button
              disabled={submitting || pristine || asyncValidating}
              className="btn-start-trial"
              type="submit">Create Account</button>
          </li>
          <li>
            <div className="center">
              By clicking "Create Account", you agree to Astronaut's <a href={'/termsofservice'} target="_blank" rel="noopener noreferrer" className="underline">Terms of Service</a> and <a href={'/privacypolicy'} target="_blank" rel="noopener noreferrer" className="underline">Privacy Policy</a>.
            </div>
          </li>
        </ul>
    	</form>
    )
  }
}

UserRegisterWizardFormFirstPageGreenHouse = reduxForm({
  form: 'UserRegisterWizardFormFirstPageGreenHouse',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateUserForGreenHouse
})(UserRegisterWizardFormFirstPageGreenHouse)

const mapDispatchToProps = (dispatch) => {
	return {
    usersValidateEmailOnUserRegisterWizardFormRequest: (email) => dispatch(usersValidateEmailOnUserRegisterWizardFormRequest(email)),
    usersSaveDataRequestGreenHouse: (formName) => dispatch(usersSaveDataRequestGreenHouse(formName))
	}
}

export default connect(null, mapDispatchToProps)(UserRegisterWizardFormFirstPageGreenHouse)
