// @flow

import React, { Fragment } from 'react'
import $ from 'jquery'
import { Link } from 'react-router-dom'
import logoAstrnt from '../../images/public/logo-astrnt-black.svg'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import initializers from '../../dependencies/initializers'

import AstrntLogoWhite from '../../styles/img/astro-logo-white.svg?v=1'
import AstrntLogo from '../../styles/img/astro-logo.svg?v=1'

import campusesIMG from '../../styles/img/stock-for/campuses.jpg'
import professionalsIMG from '../../styles/img/stock-for/professionals.jpg'
import technicalIMG from '../../styles/img/stock-for/technical.jpg'
import remoteIMG from '../../styles/img/stock-for/remote.jpg'
import globalIMG from '../../styles/img/stock-for/global.jpg'
import partnerIMG from '../../styles/img/stock-for/partner.jpg'

type Props = {
	isNavbarFixed: boolean
};

class HeaderGreenHouse extends React.Component<Props> {
	static defaultProps = {
		isOnStatusPricing: '',
		isOnStatusCode: '',
	}
	constructor(props) {
		super(props)
		this.state = {
		menu: false
		};

		bindAll(this, [
			'handleRegisterLink',
			'toggleMenu'

		])
	}

	toggleMenu(){
    this.setState({ menu: !this.state.menu })
  }

	handleLoginLink(e: Object): void {
		e.preventDefault()
			window.location = initializers.recruiter_host+'/auth/login'
	}

	handleRegisterLink(e: Object): void {
		e.preventDefault()
		const{
			history
		}=this.props

		history.push('/register')

	}

	componentDidMount() {
		var flagHead = false;
	    var scrollTop = $(window).scrollTop();

	    $(window).scroll(function(){
	        scrollTop = $(this).scrollTop();

	        if(scrollTop >= 100){
	            if(flagHead == false){
	                flagHead = true;
	                $('header').addClass('on-scroll');
	                $('.logo img').fadeOut('fast', function(){
	                    $(this).attr('src', AstrntLogo).fadeIn('fast');
	                });
	            }
	        }else{
	            if(flagHead == true){
	                flagHead = false;
	                $('header').removeClass('on-scroll');
	                $('.logo img').fadeOut('fast', function(){
	                    $(this).attr('src', AstrntLogoWhite).fadeIn('fast');
	                });
	            }
	        }

	        $('.ban-img').css({'transform': 'translateY(' + scrollTop / 3 + 'px)'});
	    });

	    $('header a.nav-mob').click(function(event){
	        event.preventDefault();

	        if($('header ul.main-nav').is(':hidden')){
	            $(this).find('i').fadeOut('fast', function(){
	                $(this).attr('class', 'fas fa-times').fadeIn('fast');
	            });
	            $('header ul.main-nav').slideDown('fast');
	        }else{
	            $(this).find('i').fadeOut('fast', function(){
	                $(this).attr('class', 'fas fa-bars').fadeIn('fast');
	            });
	            $('header ul.main-nav').slideUp('fast');
	            closeFor();
	        }
	    });

	    function closeFor(){
	        $('header ul.main-nav li a.is-open').removeClass('is-open');
	        $('header a.on-before').addClass('is-open');
	        $('header ul.for-nav').slideUp('fast');
	        $('.overlay-header').fadeOut('fast');
	    }

	    $('header ul.main-nav li:first-child a').click(function(event){
	        event.preventDefault();

	        if(!$(this).hasClass('is-open')){
	            $('header a.is-open').addClass('on-before').removeClass('is-open');
	            $(this).addClass('is-open');
	            $('header ul.for-nav').slideDown('fast');
	            $('.overlay-header').fadeIn('fast');
	        }else{
	            closeFor();
	        }
	    });

	    $('.overlay-header').click(function(){
	        closeFor();
	    });
	}

  render() {
	const show = (this.state.menu) ? "show" : "" ;
	const {
		isOnStatusPricing,
		isOnStatusCode,
	} = this.props
    return (
			<Fragment>
				<header>
		          <section>
		            <Link to="/" className="logo"><img src={AstrntLogoWhite} /></Link>
		            <a href="#" className="nav-mob"><i className="fas fa-bars" /></a>
		            <ul className="main-nav greenhouse-nav">
		            	<li></li>
		            	<li></li>
		            	<li></li>
		            	<li></li>
		              <li><a className="cursor-pointer sign-in-greenhouse" onClick={this.handleLoginLink}>Sign In <i className="fas fa-sign-in-alt" /></a></li>

		            </ul>
		          </section>
		          <ul className="for-nav" style={{display: 'none'}}>

		          </ul>
		        </header>
		        <div className="overlay-header" style={{display: 'none'}} />
		    </Fragment>
		)
  }
}

HeaderGreenHouse.defaultProps = {
	isNavbarFixed: true
}

const mapStateToProps = (state, ownProps) => {
	return {

	}
}

export default withRouter(connect(mapStateToProps,null)(HeaderGreenHouse))
